import { getApi } from "./BaseService";
import store from "../store";
export const reviewRequests = (status, search, page ) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return getApi({
		url: "review-request",
		data: {
			store_id: !is_admin ?  store_ids[0] : undefined,
			search: search?.trim?.() ?? undefined,
			status,
			page: page ?? 1,
		},
	}).then((res) => res.data);
};
