import React from "react";
import SettingLayout from "../../components/settings/SettingsLayout";
import PrimaryTable from "../../components/common/table/PrimaryTable";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";

const Deliverability = () => {

    const columns = [
        {
            name: '',
            selector: row => row.name,
            width: '100px'
        },
        {
            name: '',
            selector: row => row.email,
            width: '250px'
        },
        {
            name: '',
            selector: row => row.description,
            grow: '2'
        },
        {
            name: '',
            selector: row => row.action,
        },
    ];

    const data = [
        {
            name: 'cname',
            email: 'sta2._domainkey.inspireuplift.com',
            description: 'sta2.domainkey.u2081612.wl113.sendgrid.net',
            action: <img src="/images/icons/green-tick.svg" className="h-[15px] w-[20px]" alt="success icon" />
        },
        {
            name: 'cname',
            email: 'sta2._domainkey.inspireuplift.com',
            description: 'sta2.domainkey.u2081612.wl113.sendgrid.net',
            action: <img src="/images/icons/green-tick.svg" className="h-[15px] w-[20px]" alt="success icon" />
        },
        {
            name: 'cname',
            email: 'sta2._domainkey.inspireuplift.com',
            description: 'sta2.domainkey.u2081612.wl113.sendgrid.net',
            action: <img src="/images/icons/green-tick.svg" className="h-[15px] w-[20px]" alt="success icon" />
        },

    ];

    return (
        <div>
            <SettingLayout>
                <div className="flex justify-between p-6">
                    <div className="w-[20%]">
                        <div className="text-sm font-medium">
                            SPF/DKIM records
                        </div>
                        <div className="mt-3 text-sm">
                            Authenticate your from address domain to improve email deliverability
                        </div>
                    </div>
                    <div className="w-9/12">
                        <p className="text-sm">Please add the following DNS records with your domain registrar:</p>
                        <div>
                            <PrimaryTable
                                columns={columns}
                                data={data}
                            />
                        </div>
                        <div>
                            <PrimaryButton
                                position='prepend'
                                icon='/images/icons/white-circle-arrow.svg'
                                content='Validate Records'
                                styling='flex justify-center items-center  bg-[#60579E] text-white w-[224px] h-[40px] border border-[#BDBDBD] rounded text-sm'
                            />
                        </div>
                    </div>
                </div>
            </SettingLayout>
        </div>
    )
}
export default Deliverability;