import React from "react";
import PrimaryHeading from "../common/headings/PrimaryHeading";
import SettingNav from "../common/navbar/SettingNav";

const SettingLayout = ({ children }) => {
    return (
        <div>
            <PrimaryHeading
                heading='Settings'
            />
            <div className="mt-6">
                <SettingNav />
                <div className="bg-white mt-6">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default SettingLayout;