import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import PrimaryInput from "../components/common/inputs/PrimaryInput";
import { loginUser } from "../services/AuthService";
const MySwal = withReactContent(Swal);

const Login = () => {
	const [fields, setFields] = useState({
		email: "",
		password: "",
	});
	const [loading, setLoading] = useState(false);

	const history = useNavigate();

	const handleChange = (e) => {
		const fieldsobj = { ...fields };
		const name = e.target.name;
		const value = e.target.value;
		fieldsobj[name] = value;
		setFields(fieldsobj);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		loginUser(fields)
			.then((res) => {
				if (res.data.token) {
					MySwal.fire({
						title: "Login Successfully",
						icon: "success",
						showConfirmButton: false,
						timer: 2000,
					});
					let customerToken = res.data.token;
					localStorage.setItem(
						"token",
						JSON.stringify(customerToken)
					);
					history("/dashboard");
				}
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data?.message) {
					MySwal.fire({
						icon: "error",
						title: `${err?.response?.data.message}`,
					});
				} else {
					MySwal.fire({
						icon: "error",
						title: JSON.stringify(err?.response),
					});
				}
			})
			.finally((res) => setLoading(false));
	};

	return (
		<>
			{loading && (
				<div className="fixed z-[10000] top-0 left-0 w-full h-full bg-white bg-opacity-10 flex justify-center items-center">
					<div className="w-80 sm:w-96 h-48 bg-opacity-90 rounded bg-gray-400 text-white flex items-center justify-center text-lg flex-col progress-popup-container">
						<svg
							className=" mb-1 animate-spin -ml-1 mr-3 h-11 w-11 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
							></circle>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
						Loading...
					</div>
				</div>
			)}
			<div className="w-[95%]  lg:w-[60%] m-auto mt-12">
				<img src="/images/login-logo.png" alt="logo icon" />
				<form onSubmit={handleSubmit}>
					<div className="mt-16">
						<h1 className="font-semibold text-[32px]">Login</h1>
						<p className="mt-2.5 text-[13px] text-[#828282]">
							Enter your credentials to access your account
						</p>
					</div>
					<div className="mt-6">
						<PrimaryInput
							label="Your Email"
							width="w-full"
							placeholder="Enter your Email"
							value={fields.email}
							type="email"
							handleChange={handleChange}
							name="email"
						/>
					</div>
					<div className="mt-6">
						<PrimaryInput
							label="Password"
							width="w-full"
							placeholder="Enter your Password"
							type="password"
							value={fields.password}
							handleChange={handleChange}
							name="password"
						/>
					</div>
					{/* <div className="flex justify-between items-center mt-6">
                    <div>
                        <input type="checkbox" />
                        <label className="text-sm ml-2.5">Remember me</label>
                    </div>
                    <div className="text-sm text-[#60579E]">
                        Forgot Password?
                    </div>
                </div> */}
					<button
						disabled={loading}
						type="submit"
						className="py-3 text-center bg-[#60579E] text-white text-sm w-full mt-6"
					>
						Login
					</button>
					{/* <div className="mt-6 text-sm">
                    <span className="">Not registered yet?</span><span className="text-[#60579E]"> Create an Account</span>
                </div> */}
				</form>
			</div>
		</>
	);
};
export default Login;
