import React from "react";
import SettingLayout from "../../../components/settings/SettingsLayout";
import BillingLayout from "../../../components/settings/BillingLayout";
import PrimaryTable from "../../../components/common/table/PrimaryTable";

const BillingHisory = () => {

    const columns = [
        {
            name: 'Date',
            selector: row => row.date,
            grow: "2"
        },
        {
            name: 'Amount ($)',
            selector: row => row.amount,
            width: '120px'
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: 'Invoice',
            selector: row => row.invoice,
        },
    ];

    const billingData = [
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },
        {
            date: 'Dec 27,2021',
            amount: '549',
            status: 'Paid',
        },

    ];

    const data = billingData?.map((item, index) => {
        const { date, amount, status, } = item;

        return {
            date,
            amount,
            status,
            invoice: <img src='/images/icons/invoice.svg' />,
        }

    });


    return (
        <SettingLayout>
            <BillingLayout>
                <div className="p-6">
                    <PrimaryTable
                        columns={columns}
                        data={data}
                        selectable
                    />
                </div>
            </BillingLayout>
        </SettingLayout>
    )
}

export default BillingHisory;