import React from "react";
import { useState } from "react";

const PrimaryAccordion = ({ title, description }) => {
    const [isOpened, setOpened] = useState(false);

    const handleAccordion = () => {
        setOpened(!isOpened)
    }

    return (
        <div className="p-6 border border-[#BDBDBD] rounded w-full cursor-pointer" onClick={handleAccordion}>
            <div className="flex justify-between items-center">
                <div className="text-sm">{title}</div>
                <img src="/images/icons/rectangle.png" className="h-[4px] w-[6px]" alt="" />
            </div>
            {
                isOpened ? (
                    <div className="mt-6 text-sm text-[#828282]">
                        {description}
                    </div>
                ) :
                    null
            }

        </div>
    )

}
export default PrimaryAccordion;