import React from "react";
import PrimaryPopupWrapper from '../common/popup/PrimaryPopup';
import PrimaryInput from '../common/inputs/PrimaryInput';
import PrimaryButton from '../common/buttons/PrimaryButton';

function AddPaymentCardPopup({ handleAddPayment }) {
    return (
        <PrimaryPopupWrapper title="Add Payment Method" handleClosePopup={handleAddPayment}>
            <div className="p-6 border-b border-[#BDBDBD]">
                <div className="">
                    <span className="text-sm mr-2.5">Payment Card</span>
                    <span className="text-[13px] text-[#828282]">Mastercard, Visa, American Express, Discover</span>
                </div>
                <div className="mt-6">
                    <div className="flex justify-between w-full items-center">
                        <span className="text-sm hidden sm:block mr-4" >Card Number</span>
                        <div className="payment-method-section-cards-check-icons  items-center flex">
                            <label className="credit-card-icons1" htmlFor="credit-payment-chk "></label>
                            <label className="credit-card-icons2" htmlFor="credit-payment-chk"></label>
                            <div className="text-xs ml-3 mt-1">
                                and more...
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">

                    <div className="flex items-center px-3.5 py-1.5 border border-[#BDBDBD] rounded">
                        <img src="/images/icons/card-number.png" className="h-[22px] w-[30px]" />
                        <input type="text" className="ml-2.5 border-none w-[80%] outline-none placeholder-[#828282] text-[13px]" placeholder="Enter Card Number" />
                    </div>
                </div>
                <div className="mt-2.5 flex justify-between">
                    <div className="w-[48%]">
                        <PrimaryInput
                            label="Expire On"
                            width="w-full"
                            placeholder='10/20/2026'
                        />
                    </div>
                    <div className="w-[48%]">
                        <PrimaryInput
                            label="CVV"
                            width="w-full"
                            placeholder='Enter CVV (047)'
                        />
                    </div>

                </div>
            </div>
            <div className="flex p-6 justify-end items-center">
                <div>
                    <PrimaryButton
                        position='prepend'
                        icon='/images/icons/rounded-plus.svg'
                        content='Add'
                        styling='flex justify-center items-center  bg-[#60579E] text-white w-[150px] h-[40px] border border-[#BDBDBD] rounded text-sm'
                    />
                </div>
            </div>
        </PrimaryPopupWrapper>
    )
}

export default AddPaymentCardPopup