import React from "react";
import PrimaryInput from "../common/inputs/PrimaryInput";
import PrimaryButton from "../common/buttons/PrimaryButton";
import PrimaryPopupWrapper from "../common/popup/PrimaryPopup";

const ChangePasswordPopup = ({ handleChangePassword }) => {
    return (
        <PrimaryPopupWrapper title='Change Password' handleClosePopup={handleChangePassword}>
            <div className="p-6 border-b border-[#BDBDBD]">
                <div className="mb-2.5">
                    <PrimaryInput
                        label='Old Password'
                        width="w-full"
                        placeholder='Enter Old Password'
                    />
                </div>
                <div className="mb-2.5">
                    <PrimaryInput
                        label='New Password'
                        width="w-full"
                        placeholder='Enter New Password'
                    />
                </div>
                <div className="mb-2.5">
                    <PrimaryInput
                        label='Confirm Password'
                        width="w-full"
                        placeholder='Enter Confirm Password'
                    />
                </div>

            </div>
            <div className="flex p-6 justify-end items-center">
                <div className="mr-3">
                    <PrimaryButton
                        position='prepend'
                        icon='/images/icons/delete.svg'
                        content='Discard'
                        styling='flex justify-center items-center  bg-transparent w-[121px] h-[40px] border border-[#BDBDBD] rounded text-sm hover:bg-[#F6F6F6]'
                    />
                </div>
                <div>
                    <PrimaryButton
                        position='prepend'
                        icon='/images/icons/save-btn.svg'
                        content='Save'
                        styling='flex justify-center items-center  bg-[#60579E] text-white w-[150px] h-[40px] border border-[#BDBDBD] rounded text-sm'
                    />
                </div>
            </div>
        </PrimaryPopupWrapper>
    )
}
export default ChangePasswordPopup;