import React from "react";
import ReactPaginate from "react-paginate";
import "../../../styles/pagination.css";

export default function Pagination({ pageCount, handlePageClick, current_page }) {
	
	return (
		<ReactPaginate
			breakLabel="..."
			nextLabel={
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-4 h-4"
				>
					<path
						fillRule="evenodd"
						d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
						clipRule="evenodd"
					/>
				</svg>
			}
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			previousLabel={
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-4 h-4"
				>
					<path
						fillRule="evenodd"
						d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
						clipRule="evenodd"
					/>
				</svg>
			}
			renderOnZeroPageCount={null}
            forcePage={current_page - 1}
			containerClassName="pagination-container"
		/>
	);
}
