import React from "react";
const ProductInfoCard = ({ img, title, handleClick, cursor }) => {
	return (
		<div className={`flex items-center ${cursor}`} onClick={handleClick}>
			<img
				className="h-[43px] w-[43px] rounded object-cover aspect-square"
				height="43"
				width="43"
				alt="item image"
				src={img}
				onError={(e) => {
					console.log("Error");
					e.target.src = "/images/icons/bag.svg";
                    return true
				}}
			/>
			<div className="text-sm ml-4 truncate">{title}</div>
		</div>
	);
};
export default ProductInfoCard;
