import React from "react";
import SettingLayout from "../../components/settings/SettingsLayout";
import GeneralSetting from "../../components/settings/GeneralSetting";

const Settings = () => {
    return (
        <div>
            <SettingLayout>
                <GeneralSetting />
            </SettingLayout>
        </div>
    )
}
export default Settings;