import React from "react";
import PrimaryButton from "../../common/buttons/PrimaryButton";

const ShowOptionsNav = ({
	showOptions,
	handleShowOptions,
	handleOptions,
	handleAction,
}) => {
	return (
		<div className="sticky bottom-0">
			<div className="absolute right-0 -top-6">
				<PrimaryButton
					content={showOptions ? "hide" : "show"}
					handleClick={handleShowOptions}
					styling="bg-[#3B3561] text-white text-[13px] h-[23px] w-[48px] rounded"
				/>
			</div>
			{showOptions ? (
				<div className="flex flex-wrap md:flex-row justify-center items-center bg-white body-shadow   py-6 border-t border-[#BDBDBD]">
					<div className="mr-3 mb-3 lg:mb-0">
						<PrimaryButton
							position="prepend"
							icon="/images/icons/mobile.svg"
							content="Mobile"
							styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center mr-3 hover:bg-[#F6F6F6] bg-[#ffffff] text-black"
							handleClick={() => handleOptions("mobile")}
						/>
					</div>
					<div className="mr-3 mb-3 lg:mb-0">
						<PrimaryButton
							position="prepend"
							icon="/images/icons/desktop.svg"
							content="Desktop"
							styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center mr-3 hover:bg-[#F6F6F6] bg-white text-black"
							handleClick={() => handleOptions("desktop")}
						/>
					</div>
					<div className="mb-3 lg:mb-0 mr-3">
						<PrimaryButton
							position="prepend"
							icon="/images/icons/hide.svg"
							content="Hide Options"
							styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center mr-3 hover:bg-[#F6F6F6] bg-white text-black"
							handleClick={handleAction}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};
export default ShowOptionsNav;
