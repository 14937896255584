export const reviews = [
    {
        user:{
            name:"Maria Hruschak",
            country:'United States'
        },
        review: {
            rating:[1,2,3,4,5],
            customer_review:"Best Gift Ever",
            description:'Took a long time to get to me but given it was the holiday season that was to be expected. The product itself is awesome! Better than I expected.  Well worth the price and the wait to get it.',
            image:'/images/review1.png',
            title: 'Adorable Elephant Plush Toy Pillow',
            created_at:'01/11/2022'
        }

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        review: {
            rating: [1, 2, 3, 4, 5],
            customer_review: "It is so lovely 😍",
            description: 'It is so cool!! the size like bit smaller then I thought  but still amazing.Thank you selling this cool stuff',
            image: '/images/review2.png',
            title: '3D Fire breathing Dragon Night Lamp',
            created_at: '01/11/2022'
        }

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        review: {
            rating: [1, 2, 3, 4, 5],
            customer_review: "Planets Bracelet",
            description: 'This bracelet is gorgeous.  Well made and fits perfectly. I love it!!',
            image: '/images/review3.png',
            title: 'Solar System Space Bracelet',
            created_at: '01/11/2022'
        }

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        review: {
            rating: [1, 2, 3, 4, 5],
            customer_review: "Grateful for the Recliner Cover",
            description: 'I recently purchased a cover for my newly purchased Lazy boy recliner. Due to the Pandemic I was unable to get the color that I wanted. I am using the cover to protect the recliner from stains. The cover is very soft and and the quality is excellent.',
            image: '/images/review4.png',
            title: 'Poly Fleece One-Piece Comfortable Recliner Chair Cover with Pockets',
            created_at: '01/11/2022'
        }

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        review: {
            rating: [1, 2, 3, 4, 5],
            customer_review: "Best Gift Ever",
            description: 'Took a long time to get to me but given it was the holiday season that was to be expected. The product itself is awesome! Better than I expected.  Well worth the price and the wait to get it.',
            image: '/images/review5.png',
            title: 'Stardust Essential Oil Diffuser-Light Wood ',
            created_at: '01/11/2022'
        }

    },

    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        review: {
            rating: [1, 2, 3, 4, 5],
            customer_review: "Best Gift Ever",
            description: 'Took a long time to get to me but given it was the holiday season that was to be expected. The product itself is awesome! Better than I expected.  Well worth the price and the wait to get it.',
            image: '/images/review6.png',
            title: 'Stardust Essential Oil Diffuser-Light Wood ',
            created_at: '01/11/2022'
        }

    },
]