import React from "react";
export const RatingStars = () => {
    return (
        <div className="flex">
            <div className="h-[30px] flex justify-center items-center px-2 py-1 w-[30px] bg-[#F2C94C] rounded mr-1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2916 1.33785C12.5251 0.666176 13.4749 0.666175 13.7084 1.33785L16.3554 8.95274C16.4584 9.24904 16.7349 9.44994 17.0485 9.45634L25.1086 9.62059C25.8196 9.63508 26.1131 10.5385 25.5465 10.9681L19.1222 15.8386C18.8723 16.0281 18.7666 16.3532 18.8575 16.6534L21.192 24.3698C21.3979 25.0505 20.6294 25.6088 20.0457 25.2027L13.4284 20.5979C13.1709 20.4187 12.8291 20.4187 12.5716 20.5979L5.95428 25.2027C5.3706 25.6088 4.60211 25.0505 4.80803 24.3699L7.14253 16.6534C7.23337 16.3532 7.12775 16.0281 6.87778 15.8386L0.45353 10.9681C-0.113119 10.5385 0.180415 9.63508 0.891358 9.62059L8.9515 9.45634C9.26513 9.44994 9.54165 9.24904 9.64464 8.95274L12.2916 1.33785Z" fill="#FFFFFF" />
                </svg>
            </div>
            <div className="h-[30px] flex justify-center items-center px-2 py-1 w-[30px] bg-[#F2C94C] rounded mr-1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2916 1.33785C12.5251 0.666176 13.4749 0.666175 13.7084 1.33785L16.3554 8.95274C16.4584 9.24904 16.7349 9.44994 17.0485 9.45634L25.1086 9.62059C25.8196 9.63508 26.1131 10.5385 25.5465 10.9681L19.1222 15.8386C18.8723 16.0281 18.7666 16.3532 18.8575 16.6534L21.192 24.3698C21.3979 25.0505 20.6294 25.6088 20.0457 25.2027L13.4284 20.5979C13.1709 20.4187 12.8291 20.4187 12.5716 20.5979L5.95428 25.2027C5.3706 25.6088 4.60211 25.0505 4.80803 24.3699L7.14253 16.6534C7.23337 16.3532 7.12775 16.0281 6.87778 15.8386L0.45353 10.9681C-0.113119 10.5385 0.180415 9.63508 0.891358 9.62059L8.9515 9.45634C9.26513 9.44994 9.54165 9.24904 9.64464 8.95274L12.2916 1.33785Z" fill="#FFFFFF" />
                </svg>
            </div>
            <div className="h-[30px] flex justify-center items-center px-2 py-1 w-[30px] bg-[#F2C94C] rounded mr-1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2916 1.33785C12.5251 0.666176 13.4749 0.666175 13.7084 1.33785L16.3554 8.95274C16.4584 9.24904 16.7349 9.44994 17.0485 9.45634L25.1086 9.62059C25.8196 9.63508 26.1131 10.5385 25.5465 10.9681L19.1222 15.8386C18.8723 16.0281 18.7666 16.3532 18.8575 16.6534L21.192 24.3698C21.3979 25.0505 20.6294 25.6088 20.0457 25.2027L13.4284 20.5979C13.1709 20.4187 12.8291 20.4187 12.5716 20.5979L5.95428 25.2027C5.3706 25.6088 4.60211 25.0505 4.80803 24.3699L7.14253 16.6534C7.23337 16.3532 7.12775 16.0281 6.87778 15.8386L0.45353 10.9681C-0.113119 10.5385 0.180415 9.63508 0.891358 9.62059L8.9515 9.45634C9.26513 9.44994 9.54165 9.24904 9.64464 8.95274L12.2916 1.33785Z" fill="#FFFFFF" />
                </svg>
            </div>
            <div className="h-[30px] flex justify-center items-center px-2 py-1 w-[30px] bg-[#F2C94C] rounded mr-1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2916 1.33785C12.5251 0.666176 13.4749 0.666175 13.7084 1.33785L16.3554 8.95274C16.4584 9.24904 16.7349 9.44994 17.0485 9.45634L25.1086 9.62059C25.8196 9.63508 26.1131 10.5385 25.5465 10.9681L19.1222 15.8386C18.8723 16.0281 18.7666 16.3532 18.8575 16.6534L21.192 24.3698C21.3979 25.0505 20.6294 25.6088 20.0457 25.2027L13.4284 20.5979C13.1709 20.4187 12.8291 20.4187 12.5716 20.5979L5.95428 25.2027C5.3706 25.6088 4.60211 25.0505 4.80803 24.3699L7.14253 16.6534C7.23337 16.3532 7.12775 16.0281 6.87778 15.8386L0.45353 10.9681C-0.113119 10.5385 0.180415 9.63508 0.891358 9.62059L8.9515 9.45634C9.26513 9.44994 9.54165 9.24904 9.64464 8.95274L12.2916 1.33785Z" fill="#FFFFFF" />
                </svg>
            </div>
            <div className="h-[30px] flex justify-center items-center px-2 py-1 w-[30px] bg-[#9E9E9E] rounded">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2916 1.33785C12.5251 0.666176 13.4749 0.666175 13.7084 1.33785L16.3554 8.95274C16.4584 9.24904 16.7349 9.44994 17.0485 9.45634L25.1086 9.62059C25.8196 9.63508 26.1131 10.5385 25.5465 10.9681L19.1222 15.8386C18.8723 16.0281 18.7666 16.3532 18.8575 16.6534L21.192 24.3698C21.3979 25.0505 20.6294 25.6088 20.0457 25.2027L13.4284 20.5979C13.1709 20.4187 12.8291 20.4187 12.5716 20.5979L5.95428 25.2027C5.3706 25.6088 4.60211 25.0505 4.80803 24.3699L7.14253 16.6534C7.23337 16.3532 7.12775 16.0281 6.87778 15.8386L0.45353 10.9681C-0.113119 10.5385 0.180415 9.63508 0.891358 9.62059L8.9515 9.45634C9.26513 9.44994 9.54165 9.24904 9.64464 8.95274L12.2916 1.33785Z" fill="#FFFFFF" />
                </svg>
            </div>
        </div>
    )
}
export default RatingStars;