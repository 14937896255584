import React from "react";
import { useState } from "react";

import WidgetMainLayout from "../../components/widgets/main/WidgetMainLayout";
import ProductRatingCompoent from "../../components/widgets/main/ProductRatingComponent";
import CustomizationsForm from "../../components/widgets/main/CustomizationsForm";
import ShowOptionsNav from "../../components/widgets/main/ShowOPtionsAction";
import CssEditorForm from "../../components/widgets/main/CssEditorForm";
import InstallWidgetForm from "../../components/widgets/main/InstallWidgetForm";

const MainWidgets = () => {
	const [selectedIndex, setSelectedIndex] = useState();
	const [showOptions, setShowOptions] = useState(true);
	const [optionType, setOptionType] = useState("desktop");

	const handleAction = (index) => {
		setSelectedIndex(index);
	};

	const handleShowOptions = () => {
		setShowOptions(!showOptions);
	};

	const handleOptions = (type) => {
		setOptionType(type);
	};

	return (
		<div>
			<WidgetMainLayout
				selectedIndex={selectedIndex}
				handleAction={handleAction}
			>
				{selectedIndex === 1 ? (
					<div className="flex flex-col xl:flex-row justify-between">
						<div className="w-full xl:w-[30%] mb-3  xl:mb-0">
							<CustomizationsForm />
						</div>
						<div className={`w-full xl:w-[68%] relative`}>
							<ProductRatingCompoent optionType={optionType} />
							<ShowOptionsNav
								showOptions={showOptions}
								handleShowOptions={handleShowOptions}
								handleOptions={handleOptions}
								handleAction={handleAction}
							/>
						</div>
					</div>
				) : selectedIndex === 2 ? (
					<div className="flex flex-col xl:flex-row justify-between">
						<div className="w-full xl:w-[30%] h-full bg-white body-shadow mb-3 xl:mb-0 rounded">
							<CssEditorForm />
						</div>
						<div className="w-full xl:w-[68%] rounded">
							<ProductRatingCompoent />
						</div>
					</div>
				) : selectedIndex === 3 ? (
					<div className="flex flex-col lg:flex-row justify-between">
						<div className="w-full lg:w-[30%] rounded body-shadow mb-3 lg:mb-0 overflow-hidden">
							<InstallWidgetForm />
						</div>
						<div className="w-full lg:w-[68%]">
							<ProductRatingCompoent />
						</div>
					</div>
				) : (
					<ProductRatingCompoent optionType={optionType} />
				)}
			</WidgetMainLayout>
		</div>
	);
};
export default MainWidgets;
