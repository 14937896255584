import React from "react";
import SettingLayout from "../../components/settings/SettingsLayout";
import AppConnectCard from "../../components/settings/AppCard";

const AppSetting = () => {

    const appConnectedData = [
        {
            icon: '/images/icons/social-icons/Google-Drive.svg',
            title: 'Google Drive',
            description: 'Google Drive allows users to store fileds on their servers, sync them across devices',
            connect: true

        },
        {
            icon: '/images/icons/social-icons/Discord.svg',
            title: 'Discord',
            description: 'Lörem ipsum suprans saras, respektive högt i gåtugen väferade mest för tevörade aliga att treng. Nevis tinde',
            connect: true

        },
        {
            icon: '/images/icons/social-icons/Github.svg',
            title: 'Github',
            description: 'Lörem ipsum suprans saras, respektive högt i gåtugen väferade mest för tevörade aliga att treng. Nevis tinde',
            connect: false

        },
    ];

    return (
        <div>
            <SettingLayout>
                <div className="p-6 body-shadow rounded">
                    <div className="flex justify-between flex-wrap">
                        {
                            appConnectedData.map((item, index) => {
                                return (
                                    <div className="md:mr-4 connect-app-card mb-3" key={index} >
                                        <AppConnectCard item={item} />
                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </SettingLayout>
        </div>
    )
}
export default AppSetting;