import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import PrimaryHeading from "../components/common/headings/PrimaryHeading";
import PrimaryTable from "../components/common/table/PrimaryTable";
import PrimarySearch from "../components/common/inputs/PrimarySearch";
import PrimaryButton from "../components/common/buttons/PrimaryButton";
import SpinnerLoader from "../components/common/loader/SpinnerLoader";
import { getCustomersList } from '../services/CustomerService';
import CustomerInfo from "../components/customer/CustomerInfo";

const Customers = () => {
    const [loading, setLoading] = useState(true);
    const [getCustomers, setCustomers] = useState([]);
    const history = useNavigate();


    useEffect(() => {
        getCustomersList()
            .then(res => setCustomers(res.customers.data))
            .catch(err => {
                console.log(err);
            })
            .finally(res => setLoading(false));

    }, [])

    const buttons = [
        {
            icon: '/images/icons/upward-arrow.svg',
            title: 'Export'
        },

    ]

    const columns = [
        {
            name: '',
            selector: row => row.info,
            grow: "2"
        },
        {
            name: '',
            selector: row => row.reviews,
        },
        {
            name: '',
            selector: row => row.created_at,
        },
        {
            name: '',
            selector: row => row.action,
        },
    ];

    const data = getCustomers?.map((customer, index) => {
        const { created_at, customer_email, customer_name, reviews } = customer;
        return {
            info: <CustomerInfo name={customer_name} email={customer_email} />,
            reviews: `${reviews} reviews`,
            created_at: <div><span className="text-[#828282] text-[13px]">Joined at</span><span className="pl-2 text-sm">{created_at ? moment(created_at).format('MMMM DD,YYYY') : ''}</span></div>,
            action: <img src="/images/icons/message.svg" alt="message icon" />
        }
    });


    return (
        <div className="customers-page-container">
            <PrimaryHeading
                heading="Customers"
                buttons={buttons}
            />
            <div className="bg-white mt-4 rounded body-shadow p-6">
                <div className="flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-4/6 mb-3 lg:mb-0">
                        <PrimarySearch placeholder="Search Review Requests" />
                    </div>
                    <div className="w-full lg:w-[30%] flex">
                        <PrimaryButton
                            position="prepend"
                            icon="/images/icons/filter-icon.svg"
                            styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center mr-3"
                            content="Filter"
                        />
                        <PrimaryButton
                            position="prepend"
                            icon="/images/icons/sort.svg"
                            styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center"
                            content="Sort By"
                        />
                    </div>
                </div>
                <div className="">
                    {
                        loading ? (
                            <SpinnerLoader />
                        ) :
                            (
                                <PrimaryTable
                                    columns={columns}
                                    data={data}
                                />
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default Customers;