import React from "react";
import PrimaryButton from "../common/buttons/PrimaryButton";
import RichTextEditor from "../common/Editor/RichTextEditor";

const ReplyQuestion = ({
	handleSentReplay,
	customer,
	handleChange,
	value,
	handleCancelSent,
}) => {
	return (
		<div className="border border-[#BDBDBD] rounded">
			<div className="flex items-center p-2">
				<div className="w-[50%] overflow-auto">
					<span className="text-[#828282] text-[13px]">From:</span>
					<span className="text-sm ml-1">
						Inspire Uplift(contact@inspireuplift.com)
					</span>
				</div>
				<div className="ml-4 border-l overflow-auto  border-[#BDBDBD] w-[50%] ">
					<span className="pl-4 text-[#828282] text-[13px]">To:</span>
					<span className="text-sm ml-1">
						{`${customer?.first_name} ${customer?.last_name}`}
					</span>
				</div>
			</div>
			{/* <textarea
				onChange={handleChange}
				value={value}
				rows="5"
				cols="5"
				className="w-full outline-none text-sm p-2 border border-[#BDBDBD]"
			></textarea> */}
			<RichTextEditor value={value?.html} handleChange={handleChange}  />
			<div className="flex  items-center p-2">
				<div className="mr-3">
					<PrimaryButton
						handleClick={handleSentReplay}
						icon="/images/icons/share.svg"
						styling="border bg-trasnparent border-[#BDBDBD] rounded sm:w-[150px] px-2 py-2 sm:px-0 flex items-center justify-center"
						content="Send"
						position="prepend"
					/>
				</div>
				<div className="mr-3">
					<PrimaryButton
						handleClick={handleCancelSent}
						styling="border bg-trasnparent border-[#BDBDBD] rounded sm:w-[150px] px-2 py-2 sm:px-0 flex items-center justify-center"
						content="Cancel"
					/>
				</div>
				{/* <div className="flex lg:items-center flex-col lg:flex-row">
                    <div className="text-[13px] mr-3  mb-3 lg:mb-0">
                        <input type="checkbox" />Notify by email
                    </div>
                    <div className="text-[13px] mr-3  flex items-center">
                        <input type="checkbox" />Private
                    </div>
                </div> */}
			</div>
		</div>
	);
};
export default ReplyQuestion;
