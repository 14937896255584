import { getApi } from "./BaseService";
import store from "../store";

// GET
export function getProductsList() {
	const state = store.getState();
	const { store_ids } = state.auth.user;
	return getApi({
		url: "product",

		data: {
			is_seller: true,
			store_id: store_ids[0],
		},
	}).then((res) => res.data);
}
