import axios from "axios";
import store from "../store";

const instance = axios.create();
const state = store.getState();

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			localStorage.removeItem("token");
			if (state?.auth?.user?.is_admin) {
				window.location.href =
					"https://reviewitsfront.itdeptiu.com:3000/login";
				if (window.location.hostname.includes("inspireuplift")) {
					window.location.href =
						"https://inspireuplift.reviewits.com/login";
				} else {
					window.location.href =
						"https://reviewits-ust.itdeptiu.com/login";
				}
			} else if (
				window.location.href ==
					"https://reviewitsfront.itdeptiu.com:3000/login" ||
				window.location.href ==
					"https://reviewits-ust.itdeptiu.com/login" ||
				window.location.href ==
					"https://inspireuplift.reviewits.com/login"
			) {
				return true;
			} else {
				if (window.location.hostname.includes("inspireuplift")) {
					window.location.href =
						"https://sellercentral.inspireuplift.com/seller/login";
				} else {
					window.location.href =
						"https://sellercentral.itdeptiu.com/seller/login";
				}
			}
		}
		return Promise.reject(error);
	}
);

export default instance;
