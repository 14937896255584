import axiosInstance from "./axiosInstance";
import { getApi, getHttpHeaders, getToken } from "./BaseService";
import { siteApiUrl } from "../config";
import store from "../store";

// GET
export const getQuestionsList = ( status, page, search ) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;

	return getApi({
		url: "question",
		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status,
			page,
			search,
		},
	}).then((res) => res.data);
};

export const getSingleQuestion = (id, status) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return getApi({
		url: `question/${id}`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status: status,
		},
	}).then((res) => res.data);
};

// POST

export const approveQuestion = (id, status) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}question/${id}/approve`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};

export const updateQuestion = (id, question) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}question/${id}/update`,

		data: {
			question,
			store_id: !is_admin ? store_ids[0] : undefined,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};

export const changeQuestionStatus = (question_id, status) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}question/update/status/${question_id}`,
		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};

export const createAnswer = (question_id, answer) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}answer/create`,
		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			answer,
			question_id,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};
