import React from "react";
import { useState, useEffect } from "react";
import * as moment from 'moment/moment';
import { useNavigate } from "react-router-dom";

import PrimaryHeading from "../components/common/headings/PrimaryHeading";
import PrimarySearch from "../components/common/inputs/PrimarySearch";
import PrimaryButton from "../components/common/buttons/PrimaryButton";
import PrimaryTable from "../components/common/table/PrimaryTable";
import SpinnerLoader from "../components/common/loader/SpinnerLoader";
import { getProductsList } from "../services/ProductService";
import ProductInfoCard from "../components/product/ProductInfoCard";


const Products = () => {

    const [getProducts, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useNavigate();

    useEffect(() => {
        getProductsList()
            .then(res => setProducts(res?.data?.data))
            .catch(err => {
                console.log(err);
            })
            .finally(res => setLoading(false));
    }, []);


    const buttons = [
        {
            icon: '/images/icons/upward-arrow.svg',
            title: 'Export'
        },
    ];

    const columns = [
        {
            name: 'Product',
            selector: row => row.product,
            grow: "4"
        },
        {
            name: 'Rating',
            selector: row => row.rating,
        },
        {
            name: 'Reviews',
            selector: row => row.reviews,
        },
        {
            name: 'Last Review Date',
            selector: row => row.latest_review,
        },
    ];

    const data = getProducts?.map((item, index) => {
        const { product, image, rating, reviews, latest_review } = item;
        return {
            product: <ProductInfoCard title={product} img={image} />,
            rating,
            reviews,
            latest_review: latest_review ? moment(latest_review).format('MMMM  DD ,YYYY') : 'No Review'
        }
    });
    return (
        <div>
            <PrimaryHeading
                heading="Products"
                buttons={buttons}
            />
            <div className="bg-white mt-4 rounded body-shadow p-6">
                <div className="flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-4/6 mb-3 lg:mb-0">
                        <PrimarySearch placeholder="Search Review Requests" />
                    </div>
                    <div className="w-full lg:w-[30%] flex">
                        <PrimaryButton
                            position="prepend"
                            icon="/images/icons/filter-icon.svg"
                            styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center mr-3"
                            content="Filter"
                        />
                        <PrimaryButton
                            position="prepend"
                            icon="/images/icons/sort.svg"
                            styling="border border-[#BDBDBD] rounded w-[149px] py-3 flex items-center justify-center"
                            content="Sort By"
                        />
                    </div>
                </div>
                <div className="mt-6">
                    {
                        loading ? (
                            <SpinnerLoader />
                        ) :
                            (
                                <PrimaryTable
                                    columns={columns}
                                    data={data}
                                />
                            )
                    }
                </div>
            </div>
        </div>
    )
}
export default Products;