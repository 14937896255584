import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SpinnerLoader from "../components/common/loader/SpinnerLoader";
import { getUserInfo, verifyUser } from "../services/AuthService";
import { login } from "../store/slices/authSlice";


function AuthWrapper({ children }) {
	const history = useNavigate();
	const location = useLocation();

	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(!location?.pathname?.includes("/login")){
			const code = new URLSearchParams(location.search);
			if (code.has("code")) {
				setLoading(true);
				verifyUser(code.get("code")).then(() => {
					localStorage.setItem("token", JSON.stringify(code.get("code")));
					history("/dashboard");
				});
			} else  {
				if (!authState.isLoggedIn) {
					getUserInfo().then((res) => {
						const { data } = res;
						dispatch(login({...data, is_admin:res.is_admin}));
						if (location.pathname == "/") {
							history("/dashboard");
						}
						setLoading(false);
					});
				} else {
					if (loading) {
						setLoading(false);
					}
					if (location.pathname == "/") {
						history("/dashboard");
					}
				}
			}
		}
		else {
			if(location.pathname == "/login"){
				getUserInfo().then((res) => {
					if(res?.is_admin) {
						const { data } = res;
						dispatch(login({...data, is_admin:res.is_admin}));
						history("/dashboard");
					}
				})
				.catch((err) => console.log(err))
				.finally(res => 	setLoading(false)); 
			}
			else{ 
				setLoading(false);
			}
			
		}

	}, [location]);

	if (loading) {
		return (
			<div className="fixed top-0 left-0 w-full h-full">
				<SpinnerLoader />
			</div>
		);
	} else {
		return <>{children}</>;
	}
}

export default AuthWrapper;
