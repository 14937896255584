import React from 'react'

const FilterTag = ({ name, handleClear }) => {
  return (
    <div className='flex items-center rounded-full bg-[#E9E9E9] py-[4.5px] px-3'>
        <span className='text-[#828282] text-[13px] pr-[7px]'>{name}</span>
        <img onClick={handleClear} src='/images/icons/cross.svg' alt='clear icon' className='cursor-pointer' height={6} width={6}  />
    </div>
  )
}

export default FilterTag;
