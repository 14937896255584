import React from "react";
import { Link } from "react-router-dom";

const DashboardCard = ({ item, hyperText, hyperLink }) => {
	const { icon, title, count, percentage } = item;
	return (
		<div className="py-[22px] px-6 bg-white  info-card-container flex justify-between rounded mt-4 lg:mt-0 lg:mr-4">
			<div className="">
				<div className="font-medium text-[21px] leading-none">{count}</div>
				<p className="text-[#828282] text-sm mt-[15px]">{title}</p>
				{item?.unanswered > 0 && (
					<Link
						to={"/questions?type=unanswered"}
						className={"text-[#007185] text-[14.4px] mt-[15px] block"}
					>
						{item?.unanswered} Unanswered{" "}
					</Link>
				)}
			</div>
			<img src={icon} className="h-[60px] w-[60px] self-center" alt="dashboad icon" />
		</div>
	);
};
export default DashboardCard;
