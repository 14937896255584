import React from "react";
import moment from "moment";
import Rating from "react-rating";
import { StarIcon as FilledStar } from "@heroicons/react/solid";

const ReviewCard = ({
	time,
	title,
	description,
	rating,
	handleClick,
	cursor,
	type,
}) => {
	const formattedDate = time
		? moment(time, "YYYY-MM-DD hh:mm:ss A").format("DD-MM-YYYY")
		: null;
	return (
		<div className={`w-[440px] p-1 ${cursor}`} onClick={handleClick}>
			<div className="flex justify-between items-center">
				{rating ? (
					<div
						className={`rating-stars-innerwrapper flex items-center `}
					>
						<Rating
							initialRating={rating}
							readonly
							emptySymbol={
								<div className="w-5 h-5">
									<FilledStar color="#BDBDBD" />
								</div>
							}
							fullSymbol={
								<div className="w-5 h-5">
									<FilledStar color="#FFC200" />
								</div>
							}
						/>
					</div>
				) : title ? (
					<p className="text-[#232323] mt-1 font-medium text-sm">
						{title}
					</p>
				) : null}
			</div>
			{rating && title && (
				<p className="text-[#232323] mt-1 font-medium text-sm">
					{title}
				</p>
			)}
			<div>
				{type ? (
					<p className="text-[#4F4F4F] text-sm mt-1 whitespace-normal">
						{description}
					</p>
				) : null}
				{formattedDate ? (
					<div className="text-[#828282] text-[13px] font-normal">
						{formattedDate}
					</div>
				) : null}
			</div>
		</div>
	);
};
export default ReviewCard;
