import React from "react";
import { useState } from 'react';

import UserRating from "./UserRating";
import ProductQuestion from "./ProductQuestion";
import ProductReview from "./ProductReview";
import PrimarySearch from "../../common/inputs/PrimaryInput";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { reviews } from "../../../json/product-reviews";
import { questions } from "../../../json/product-questions";

function ProductRatingCompoent({ optionType }) {


    const [selectIndex, setSelectIndex] = useState(1);

    const handleSelected = (index) => {
        setSelectIndex(index);
    }

    return (
        <div className="bg-white">
            <div className="border-b border-[#BDBDBD] pt-6">
                <div className={`${optionType === "mobile" ? "w-[70%] sm:w-[50%]" : "w-[95%] lg:w-[85%]"} m-auto`}>
                    <UserRating optionType={optionType} />
                    <ul className=" mt-6 flex items-center text-sm">
                        <li className={`relative mr-8  cursor-pointer ${selectIndex == 1 ? "main-active-nav" : ''}`} onClick={() => handleSelected(1)}>Reviews 49822</li>
                        <li className={`relative cursor-pointer ${selectIndex == 2 ? "main-active-nav" : ''}`} onClick={() => handleSelected(2)}>Questions 749</li>
                    </ul>
                </div>
            </div>
            <div className={`flex flex-col lg:flex-row justify-between  m-auto py-6 border-b border-[#BDBDBD] ${optionType === "mobile" ? "w-[60%]" : "w-[95%] lg:w-[85%]"}`}>
                <div className={`mb-3 lg:mb-0 ${optionType === "mobile" ? "2-full sm:w-[50%]" : "w-full lg:w-4/5"}`}>
                    <PrimarySearch placeholder="Search Review Requests" />
                </div>
                <div className={`${optionType === "mobile" ? "w-[80%] sm:w-[40%]" : "w-[50%] lg:w-1/6"}`}>
                    <PrimaryButton
                        position="prepend"
                        icon="/images/icons/sort.svg"
                        styling="border border-[#BDBDBD] rounded w-full py-3 flex items-center justify-center"
                        content="Sort By"
                    />
                </div>
            </div>
            {
                selectIndex === 1 ? (
                    reviews.map((item, index) => {
                        return (
                            <div className="border-b border-[#BDBDBD]" key={index}>
                                <div className={`m-auto ${optionType === "mobile" ? "w-[70%] sm:w-[50%]" : "w-[95%] lg:w-[85%]"}`}>
                                    <ProductReview item={item} />
                                </div>

                            </div>
                        )
                    })

                ) :
                    selectIndex === 2 ? (
                        questions.map((item, index) => {
                            return (
                                <div className="border-b border-[#BDBDBD]" key={index}>
                                    <div className={`m-auto ${optionType === "mobile" ? "w-[70%] sm:w-[50%]" : "w-[95%] lg:w-[85%]"}`}>
                                        <ProductQuestion item={item} />
                                    </div>

                                </div>
                            )
                        })
                    )
                        : null
            }

        </div>
    )
}

export default ProductRatingCompoent;