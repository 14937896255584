import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PrimaryHeading from "../components/common/headings/PrimaryHeading";
import PrimaryTable from "../components/common/table/PrimaryTable";
import SpinnerLoader from "../components/common/loader/SpinnerLoader";
import NavLinks from "../components/common/navbar/PrimaryNavbar";
import PrimarySearch from "../components/common/inputs/PrimarySearch";
import { reviewRequests } from "../services/ReviewRequestService";
import Pagination from "../components/common/pagination";


let timeout = null;
const ReviewsRequest = () => {
	const [loading, setLoading] = useState(true);
	const [getReviewsRequest, setReviewsRequest] = useState([]);
	const { search, pathname } = useLocation();
	const [search_value, setSearchValue] = useState("");
	const [current_page, setCurrentPage] = useState(1);
	const params = new URLSearchParams(window?.location?.search);
	const navigate = useNavigate();


	useEffect(() => {
		let status = params.get("type");
		let page = params.get('page') || 1;
		let searchParam = params.get('q') || undefined;
		setCurrentPage(page); 
		setSearchValue(searchParam || '');
		switch (status) {
			case "pending":
				status = 0;
				break;
			case "sent":
				status = 1;
				break;
			default:
				status = 2;
				break;
		};
		reviewRequests( status, searchParam, page )
			.then((res) => setReviewsRequest(res.data))
			.catch((err) => {
				console.log(err);
			})
			.finally((res) => setLoading(false));
	}, [search]);

	const columns = [
		{
			name: "Order",
			selector: (row) => row.order,
			width: "200px",
		},
		{
			name: "Customer",
			selector: (row) => row.customer_name,
		},
		{
			name: "Scheduled Date",
			selector: (row) => row.schedule_date,
			width: "200px",
		},
		{
			name: "Status",
			selector: (row) => row.status,
		},
		{
			name: "Item",
			selector: (row) => row.item,
		},
	];

	const navLinks = [
		{
			name: "Pending",
			link: "pending",
		},
		{
			name: "Sent",
			link: "sent",
		},
		{
			name: "Deleted",
			link: "deleted",
		},
	];

	const handleDelete = () => {
		console.log("check handle delete work.");
	};

	const data = getReviewsRequest?.data?.map((item, index) => {
		const {
			order_number,
			customer_name,
			status,
			schedule_date,
			product_image,
			product_alt,
		} = item;
		return {
			order: order_number,
			customer_name,
			schedule_date: (
				<div className="flex justify-between w-full">
					<div className="mr-10">{schedule_date}</div>
				</div>
			),
			status: (
				<div className="rounded text-[#9A7800] text-[13px] p-1 bg-[#F3EFDA] ml-1">
					{status}
				</div>
			),
			item: (
				<img
					className="review-request-item"
					src={product_image}
					onError={(e) => {
						e.target.src = "/images/icons/bag.svg";
						return true;
					}}
					alt="review request icon"
				/>
			),
		};
	});

	const contextActionButton = (selected) => {
		return (
			<div className="flex">
				<button onClick={handleDelete}>Edit</button>
				<button onClick={handleDelete}>delete</button>
			</div>
		);
	};

	const handleChange = (e) => {
		const { value } = e.target;
		setSearchValue(value);
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			if(value.trim().length > 0) {
				params.set("q",value?.trim());
			}
			else {
				params.delete('q');
			}
			
			params.delete('page');
			navigate(pathname + "?" + params);
		}, 1000);
	};

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		params.set("page",page);
		navigate(pathname + "?" + params);
	};


	return (
		<div>
			<PrimaryHeading heading="Review Request" />
			<div className="bg-white mt-4 rounded body-shadow">
				<NavLinks links={navLinks} />
				<div className="p-6">
					<div className="flex flex-col lg:flex-row justify-between">
						<div className="w-full mb-3 lg:mb-0">
							<PrimarySearch
								placeholder="Search Review Requests"
								handleChange={handleChange}
								value={search_value}
							/>
						</div>
					</div>
					<div className="mt-6">
						{loading ? (
							<SpinnerLoader />
						) : (
							<div>
								<PrimaryTable
									columns={columns}
									// selectable
									data={data}
									handleDelete={handleDelete}
									contextActionButton={contextActionButton}
								/>
							</div>
						)}
					</div>
					{getReviewsRequest?.last_page &&
					getReviewsRequest?.last_page > 1 ? (
						<Pagination
							current_page={current_page}
							pageCount={getReviewsRequest?.last_page}
							handlePageClick={handlePageClick}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ReviewsRequest;
