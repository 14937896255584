import React from "react";
const PrimarySearch = ({ placeholder, handleChange, value, className="px-3 py-3" }) => {
	return (
		<div className={`${className} flex items-center border border-[#BDBDBD] rounded`}>
			<div>
				<img
					src="/images/icons/search-icon.svg"
					className="mr-3"
					height="18"
					width="18"
				/>
			</div>
			<input
				type="text"
				className="border-0 text-[13px] placeholder-[#828282] w-full outline-none bg-transparent"
				onChange={handleChange}
				value={value}
				placeholder={placeholder}
			/>
		</div>
	);
};
export default PrimarySearch;
