import React from "react";
import { useState } from "react";

import SettingLayout from "../../components/settings/SettingsLayout";
import PrimaryInput from "../../components/common/inputs/PrimaryInput";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import ChangePasswordPopup from "../../components/settings/ChangePasswordPopup";

const AccountSetting = () => {
    const [isChangePassword, setChangePassword] = useState(false);

    const handleChangePassword = (e) => {

        if (e.target.id == 'change-password-container' || e.target.id == 'close-popup-icon') {
            setChangePassword(false);
        }
        else {
            setChangePassword(true);
        }

    }

    return (
        <div className="relative">
            <SettingLayout>
                <form>
                    <div className="p-6 flex flex-col lg:flex-row justify-between border-b border-[#BDBDBD]">
                        <div className="w-full lg:w-1/5">
                            <div className="text-sm font-medium">Account owner</div>
                            <p className="text-sm mt-3">
                                Reviewits and your customers will use this information to contact you
                            </p>
                        </div>
                        <div className="w-full lg:w-3/4">
                            <div className="flex flex-col lg:flex-row justify-between">
                                <div className="w-full lg:w-[48%]">
                                    <PrimaryInput
                                        label='Name'
                                        type="text"
                                        placeholder='Enter your name'
                                        width='w-full'
                                    />
                                </div>
                                <div className="w-full lg:w-[48%]">
                                    <PrimaryInput
                                        label='Email'
                                        type="text"
                                        placeholder='Enter your email'
                                        width='w-full'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row justify-between mt-6">
                                <div className="w-full lg:w-[48%]">
                                    <PrimaryInput
                                        label='Address'
                                        type="text"
                                        placeholder='Enter your address'
                                        width='w-full'
                                    />
                                </div>
                                <div className="w-full lg:w-[48%]">
                                    <PrimaryInput
                                        label='Phone Number'
                                        type="text"
                                        placeholder='Enter your phone number'
                                        width='w-full'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row justify-between mt-6">
                                <div className="w-full lg:w-[48%]">
                                    <PrimaryInput
                                        label='Date Format'
                                        type="date"
                                        placeholder='Enter your address'
                                        width='w-full'
                                    />
                                </div>
                                <div className="w-full lg:w-[48%]">
                                    <label className="text-sm mb-1 block">Currency</label>
                                    <select className="rounded border border-[#BDBDBD] bg-transparent placeholder-[#828282] text-[13px] py-2.5 px-3.5 w-full">
                                        <option>Select Currency</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full lg:w-[48%] mt-6">
                                <label className="text-sm mb-1 block">Currency</label>
                                <select className="rounded border border-[#BDBDBD] bg-transparent placeholder-[#828282] text-[13px] py-2.5 px-3.5 w-full">
                                    <option>(GMT-05:00) Eastern Time (US & Canada)</option>
                                </select>
                            </div>
                            <div className="mt-6">
                                <label className='text-sm mb-1 block"'>Password</label>
                                <PrimaryButton
                                    type='button'
                                    handleClick={handleChangePassword}
                                    content='Change Password'
                                    position='prepend'
                                    icon='/images/icons/circle-arrow.svg'
                                    styling='border border-[#BDBDBD] w-[230px] flex justify-center items-center rounded text-sm h-[39px] hover:bg-[#F6F6F6]'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-center p-6">
                        <PrimaryButton
                            styling='bg-transparent mr-2.5 border border-[#BDBDBD] text-black rounded w-[135px] h-[40px] flex justify-center items-center hover:bg-[#F6F6F6]'
                            content='Discard'
                            position="prepend"
                            icon="/images/icons/delete.svg"
                        />
                        <PrimaryButton
                            styling='bg-[#60579E] text-white  border border-[#BDBDBD]  rounded w-[135px] h-[40px] flex justify-center items-center'
                            content='Save'
                            position="prepend"
                            icon="/images/icons/save-btn.svg"
                        />
                    </div>
                </form>
            </SettingLayout>
            {
                isChangePassword ? (
                    <ChangePasswordPopup handleChangePassword={handleChangePassword} />
                ) : null
            }

        </div>
    )
}
export default AccountSetting;