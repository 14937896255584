export const questions = [
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        question: {
            questions:"What are the dimensions of the cushion (I want it for a wheelchair)?",
            answer:"The Ergonomic Hip Cushion Posture Corrector measures approximately 18.11 x 11.81 x 3.94 inches!",
            created_at: '01/11/2022'
        },
        title:'Ergonomic Hip Cushion Posture Corrector'

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        question: {
            questions: "Delivery date for japan?",
            answer: "We offer free worldwide shipping with 2 to 3 business days for order processing. After the order dispatch, the estimated delivery time is 7 to 10 days working depending on your country or region.",
            created_at: '01/11/2022'
        },
        title:'Gold Sun Ring Copper Material'

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        question: {
            questions: "What is the weight of a pair of mediums?",
            answer: "A pair of Silicovers Non-Slip Shoe Covers weight approximately 3 oz.",
            created_at: '01/11/2022'
        }
        ,
        title:'Stardust Essential Oil Diffuser-Light Wood'

    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        question: {
            questions: "is this beige or off white?",
            answer: 'The "Poly Fleece One-Piece Comfortable Recliner Chair Cover with Pockets" only comes in beige color!',
            created_at: '01/11/2022'
        }
        ,
        title:"Poly Fleece One-Piece Comfortable Recliner Chair Cover with Pockets  "
    },
    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        question: {
            questions: "Can this tool be used to remove a small dent in a bumper cover?",
            answer: "The Dent Removal Repair Tool Kit is suitable for vehicles. It removes dents caused by hail, car doors, shopping carts, and more, without damaging the surface of your car.",
            created_at: '01/11/2022'
        }
        ,
        title:"Adorable Elephant Plush Toy Pillow "

    },

    {
        user: {
            name: "Maria Hruschak",
            country: 'United States'
        },
        question: {
            questions: "Where can I get power bank?",
            answer: "The power bank is not included in the package. The Tech Heated Vest is heated using a Power Bank Battery 5V, 10000 mAh. You can purchase the power bank at any electronic store.",
            created_at: '01/11/2022'
        },
        title:'3D Fire breathing Dragon Night Lamp '
    },
]