import React, { useState } from "react";

const RatingFilterDropdown = ({ handleRatingFilter, selectedRating }) => {
	const [isOpen, setIsOpen] = useState(false);
	const options = [
		{ name: "1 Star Rating", value: 1 },
		{ name: "2 Star Rating", value: 2 },
		{ name: "3 Star Rating", value: 3 },
		{ name: "4 Star Rating", value: 4 },
		{ name: "5 Star Rating", value: 5 },
	];

	const handleToggle = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<div className="relative w-max">
			<button
				className="border border-gray-400 rounded flex items-center py-2 px-6 text-gray-800  text-[14.4px]"
				onClick={handleToggle}
			>
				<img
					src="/images/icons/filter-icon.svg"
					height={18}
					width={20}
					alt="filter icon"
					className="h-[18px] w-[20px]"
				/>
				<span className="pl-[5px] leading-[18.72px]">Filter by Rating</span>
				
			</button>
			{isOpen && (
				<div
					className="fixed inset-0 bg-black opacity-20 z-10"
					onClick={handleToggle}
				></div>
			)}
			{isOpen && (
				<ul className="absolute bg-white z-20 mt-2 w-full rounded shadow-lg transition-all duration-300 ease-in-out">
					{options.map((opt, index) => (
						<li
							key={opt.value} // Assuming each option has a unique value
							className={`${selectedRating == opt.value ? "bg-gray-100" : "bg-white"} text-gray-600 hover:bg-gray-100 cursor-pointer text-sm py-2 px-3 ${
								index !== options.length - 1
									? "border-b border-gray-200"
									: ""
							}`}
							onClick={() => {
								handleRatingFilter(opt);
								handleToggle();
							}}
						>
							{opt.name}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default RatingFilterDropdown;
