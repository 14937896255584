import React from "react";
import { useState } from 'react'
import { Switch } from '@headlessui/react'

function SwitchInput({ label }) {

    const [enabled, setEnabled] = useState(false)
    return (
        <div>
            {label &&
                <label className="text-sm mb-1 block">{label}</label>}
            <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${enabled ? 'bg-[#57BA80]' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11`}
            >
                <span className="sr-only">Enable notifications</span>
                <span
                    className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                />
            </Switch>
        </div>

    )
}
export default SwitchInput;
