import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

const NavLinks = ({ links }) => {
	let { pathname } = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(window?.location?.search);
	const type = params.get("type");

	const handleClick = (e, status) => {
		e.preventDefault();
		params.set("type", status);
		let keysToDelete = [];
		params.forEach((value, key) => {
			if (key !== "type") {
				keysToDelete.push(key);
			}
		});
		keysToDelete.forEach((key) => {
			params.delete(key);
		});
		navigate(pathname + "?" + params);
	};

	return (
		<>
			<div className="navlinks-container flex items-center pt-4 px-8 border-b border-[#BDBDBD] pb-[8px] overflow-auto">
				{links.map((item, index) => {
					return (
						<a
							key={index}
							className={`navlinks-items font-normal cursor-pointer mr-7 text-sm text-[#232323] rounded  block ${
								type === item.link && "active"
							} `}
							onClick={(e) => {
								handleClick(e, item.link);
							}}
						>
							{item.name}
						</a>
					);
				})}
			</div>
			{/* <div className="block relative sm:hidden pt-2">
                <div className="flex justify-end items-center px-6">
                    <DotsHorizontalIcon className="h-5 w-5 text-black" onClick={handleDropDown} />
                </div>
                {
                    showDropDown && (
                        <>
                            <div className="fixed top-0 h-full w-full bg-transparent" onClick={handleDropDown}></div>
                            <div className="absolute right-6 dropdown-menu rounded border border-[#BDBDBD] bg-clip-padding bg-white z-10">
                                {links.map((item, index) => {
                                    return (
                                        <Link href={item.link} key={index}>
                                            <a className={`block dropdown-item py-1.5 px-4 border-b border-[#BDBDBD] text-sm`}>
                                                {item.name}
                                            </a>
                                        </Link>
                                    )
                                })}
                            </div>
                        </>
                    )
                }
            </div> */}
		</>
	);
};
export default NavLinks;
