import React from "react";
import AuthWrapper from "./hoc/AuthWrapper";
import StoreLayout from "./layout/StoreLayout";
import AppRoutes from "./routes/appRoutes";


function App() {
	return (
		<AuthWrapper>
			<StoreLayout>
				<AppRoutes />
			</StoreLayout>
		</AuthWrapper>

	);
};

export default App;
