import React from "react";
import { alphabetColors } from "../../utils/string";
const CustomerInfo = ({ name, handleClick, cursor }) => {
	return (
		<div className={`flex items-center ${cursor}`} onClick={handleClick}>
			<div
				className="h-[43px] w-[43px] min-w-[43px] text-xl flex items-center justify-center rounded-full mix-blend-difference font-medium "
				style={{
					background: alphabetColors?.[name?.[0]?.toLowerCase?.()],
				}}
			>
				{name[0]?.toUpperCase?.()}
			</div>
			<div className="ml-4 flex flex-col">
				<div className="text-sm font-medium">{name}</div>
			</div>
		</div>
	);
};
export default CustomerInfo;
