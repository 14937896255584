import axiosInstance from "./axiosInstance";
import { getApi, getHttpHeaders, getToken } from "./BaseService";
import { siteApiUrl } from "../config";
import store from "../store";

// GET
export const getReviews = ( status, page, search, rating,start_date, end_date ) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return getApi({
		url: "review",

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status,
			page: page ?? 1,
			search: search ?? undefined,
			start_date: start_date || undefined,
			end_date: end_date || undefined,
			rating:rating || undefined
		},
	}).then((res) => res.data);
};

export const getSingleReview = (id) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return getApi({
		url: `review/${id}`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
		},
	}).then((res) => res.data);
};

export const productReview = (id) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return getApi({
		url: `product/review/${id}/detail`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
		},
	}).then((res) => res.data);
};

// POST

export const reviewApprove = (id, status) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}product/review/${id}/approve`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};

export const updateReview = (id, data) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;

	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}product/review/${id}/update`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			...data,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};

export const getDashboardData = ({
	start_date,
	end_date,
	store_ids,
	isAdmin,
}) => {
	return getApi({
		url: `dashboard`,
		data: {
			store_id: !isAdmin ? store_ids[0] : undefined,
			start_date: start_date,
			end_date: end_date ?? start_date,
		},
	}).then((res) => res.data);
};

export const changeReviewStatus = ({ review_id, status }) => {
	const state = store.getState();
	const { store_ids, is_admin } = state.auth.user;

	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}product/review/update/status/${review_id}`,

		data: {
			store_id: !is_admin ? store_ids[0] : undefined,
			status,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};

export const disputeReview = ({
	order_id,
	product_id,
	reason,
	type,
	product_review_id,
}) => {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}review-dispute`,

		data: {
			order_id,
			product_id,
			reason,
			type,
			product_review_id,
		},
		headers: { ...getHttpHeaders(), Authorization: `Bearer ${getToken()}` },
	});
};
