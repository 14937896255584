import React from "react";

import PrimaryHeading from "../../common/headings/PrimaryHeading";



const WidgetMainLayout = ({ children, handleAction, selectedIndex }) => {
    return (
        <div>
            <PrimaryHeading
                heading="Main Widget"
            />
            <ul className="flex mt-6 items-center">
                <li className={`w-[40%] text-center sm:w-[130px] py-2.5 px-2 ${selectedIndex === 1 ? "bg-[#F6F6F6]" : 'bg-[#FFFFFF]'}  text-sm mr-1  sm:mr-3 rounded cursor-pointer`} onClick={() => handleAction(1)}>
                    Customizations
                </li>
                <li className={`w-[35%] text-center sm:w-[100px] py-2.5 px-2 ${selectedIndex === 2 ? "bg-[#F6F6F6]" : 'bg-[#FFFFFF]'}  text-sm mr-1 sm:mr-3 rounded cursor-pointer`} onClick={() => handleAction(2)}>
                    CSS Editor
                </li>
                <li className={`w-[20%]  sm:w-[100px] py-2.5 px-1 text-center ${selectedIndex === 3 ? "bg-[#F6F6F6]" : 'bg-[#FFFFFF]'} text-sm mr-0 sm:mr-3 rounded cursor-pointer`} onClick={() => handleAction(3)}>
                    Install
                </li>
            </ul>
            <div className="mt-6">
                {children}
            </div>
        </div>
    )
}
export default WidgetMainLayout;