// src/store/store.js

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

const store = configureStore({
	reducer: rootReducer,
	// Other configuration options can be added here
});

export default store;
