import React, { useState, useEffect } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import SingleQuestionComponent from "../../components/questions/SingleQuestion";
import SpinnerLoader from "../../components/common/loader/SpinnerLoader";
import {
	getSingleQuestion,
	updateQuestion,
	changeQuestionStatus,
} from "../../services/QuestionsServices";

const SingleQuestion = () => {
	const [loading, setLoading] = useState(true);
	const [question, getQuestion] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const { questionId } = useParams();
	const history = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const type = location.search.slice(6);
		let status = 1;
		switch (type) {
			case "unanswered":
				status = 3;
				break;
			case "published":
				status = 1;
				break;
			case "unpublished":
				status = 2;
				break;
			case "archived":
				status = 4;
				break;
			default:
				status = null;
		}

		if (questionId) {
			getSingleQuestion(questionId)
				.then((res) => getQuestion(res.data))
				.catch((err) => {
					console.log(err);
				})
				.finally((res) => setLoading(false));
		}
	}, [questionId]);

	const handleEdit = () => {
		setIsEdit(!isEdit);
	};

	const handleQuestionApprove = (status) => {
		setLoading(true);
		changeQuestionStatus(questionId, status)
			.then((res) => history(-1))
			.catch((err) => console.log(err))
			.finally((res) => setLoading(false));
	};

	const handleArchive = (status) => {
		setLoading(true);
		changeQuestionStatus(questionId, status)
			.then((res) => history(-1))
			.catch((err) => console.log(err))
			.finally((res) => setLoading(false));
	};

	const handleSubmit = (question) => {
		updateQuestion(questionId, question)
			.then((res) => {
				if (res?.data?.status === false) {
					setIsEdit(true);
				} else {
					setIsEdit(false);
				}
			})
			.catch((err) => console.log(err));
	};

	const navLinks = [
		{
			name: "Unanswered",
			link: "/questions?type=unanswered",
		},
		{
			name: "Published",
			link: "/questions?type=published",
		},
		{
			name: "Unpublished",
			link: "/questions?type=unpublished",
		},
		{
			name: "Archived",
			link: "/questions?type=archived",
		},
	];

	return (
		<div>
			{loading ? (
				<div className="h-full flex justify-center items-center">
					<SpinnerLoader />
				</div>
			) : (
				<SingleQuestionComponent
					data={question}
					navLinks={navLinks}
					handleApprove={handleQuestionApprove}
					heading={"Product Question"}
					handleEdit={handleEdit}
					handleSubmit={handleSubmit}
					isEdit={isEdit}
					handleArchive={handleArchive}
					question_id={questionId}
				/>
			)}
		</div>
	);
};
export default SingleQuestion;
