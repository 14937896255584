// src/store/rootReducer.js

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
// import other reducers here

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers here
});

export default rootReducer;