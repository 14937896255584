import React from "react";
import SettingLayout from "../../components/settings/SettingsLayout";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import PlanCard from "../../components/settings/PlanCard";
import PrimaryAccordion from "../../components/common/accordion/PrimaryAccordion";

const PlansSettings = () => {

    const data = [
        {
            title: 'Basic',
            status: 'For Individuals',
            price: '19',
            type: 'Free Features and',
            features: [
                "Up to 200 orders monthly",
                "Receive a One Time Offer of $19 Free SMS credits",
                "Photo Reviews",
                "Checkout Reviews",
                "Remove Reviewits Branding",
                "Connect Reviewits to 1 of our many ‘Reviews & Ratings’ integrated apps"
            ]
        },
        {
            title: 'Premium',
            status: 'For Professionals',
            price: '39',
            type: 'Basic Features and',
            features: [
                "Up to 500 orders monthly",
                "Receive a One Time Offer of $39 Free SMS credits",
                "Receive a One Time Offer of $99 Free SMS credits",
                "Create optimized ad banners with both pre-designed and custom templates",
                "Upsell Products with our in-email recommender algorithm",
                "Connect Reviewits to 3 of our ‘Reviews & Ratings’ integrated apps"
            ]
        },
        {
            title: 'Business',
            status: 'For Mid Sized Businesses',
            price: '99',
            type: 'Premium Features and',
            features: [
                "Up to 1,500 orders monthly",
                "Request email, SMS and Facebook Messenger reviews",
                "Receive a One Time Offer of $99 Free SMS credits",
                "Reviewits - analyze and flag reviews using our proprietary smart sentiment-detection",
                "Improve SEO and engagement with Community Q&A",
                "Video Reviews",
                "Advanced Layout Editor",
                "Showcase Reviewits product reviews in Google with our Google Product Rating Integration",
                "Google Shopping Ads Integrations",
                "Connect Reviewits to 5 of our ‘Reviews & Ratings’ integrated apps"
            ]
        },
        {
            title: 'Professional',
            status: 'For Growing Business',
            price: '249',
            type: 'Business Features and',
            features: [
                "Receive a One Time Free credit of $150 SMS",
                "Enjoy powerful reporting at scale with our A.I.-powered review analysis",
                "Create shoppable galleries from your IG UGC displayed directly on your website",
                "Ads Center - Create Facebook & Instagram ads with reviews in-app",
                "Leverage NPS in Google with Google Seller Rating",
                "Survey & Monitor your Net Promoter Score",
                'Connect Reviewits to Unlimited ‘Reviews & Ratings’ Integrated apps'
            ]
        },
    ];

    return (
        <div>
            <SettingLayout>
                <div className="p-6 flex flex-col lg:flex-row justify-between border-b border-[#BDBDBD]">
                    <div className="w-full lg:w-1/5">
                        <h2 className="text-sm font-medium">Plans</h2>
                        <p className="text-sm font-normal mt-2.5">Compare our plans and find yours</p>
                        <div className="flex lg:block xl:flex h-[49px]  lg:h-auto xl:h-[49px] border border-[#BDBDBD] rounded p-[5px] mt-2.5">
                            <PrimaryButton
                                content='Monthly'
                                styling='w-[50%] lg:w-full xl:w-[50%] rounded bg-[#60579E] text-sm  text-white rounded h-[39px] flex justify-center items-center'
                            />
                            <PrimaryButton
                                content='Annual'
                                styling='w-[50%] lg:w-full xl:w-[50%] rounded bg-transparent text-sm  text-black rounded h-[39px] flex justify-center items-center'
                            />
                        </div>
                    </div>
                    <div className="w-full mt-3 lg:mt-0 lg:w-3/4">
                        <div className="flex justify-between flex-wrap">
                            {data.map((item, index) => <PlanCard key={index} item={item} />)}
                        </div>
                    </div>
                </div>
                <div className="p-6 flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-1/5 mb-3 lg:mb-0">
                        <h2 className="text-sm font-medium">FAQS</h2>
                        <p className="text-sm font-normal mt-2.5">If you cannot find answer to your question in our FAQ, you can always contact us.We will answer you shortly</p>
                    </div>
                    <div className="w-full lg:w-3/4">
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="What are the supported platforms?"
                                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="What are your operating hours?"
                                description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',"
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="How much does the plan cost? Is there a free plan or discount if I pay upfront in advance?"
                                description='Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="Is there any contract?"
                                description='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'

                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="Do I need two subscriptions for the Reviews & Ratings and Loyalty & Rewards app?"
                                description='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="Where can I download my invoices?"
                                description='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="How long does it take to set-up my account?"
                                description='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="Can I import all my reviews into Reviewits?"
                                description='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
                            />
                        </div>
                        <div className="mb-6">
                            <PrimaryAccordion
                                title="Can I customize the review request emails?"
                                description='Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
                            />
                        </div>
                    </div>
                </div>
            </SettingLayout>
        </div>
    )
}
export default PlansSettings;