import React from "react";
import SettingLayout from "../../components/settings/SettingsLayout";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";

const WebhookSetting = () => {
    return (
        <div>
            <SettingLayout>
                <div className="p-6 flex flex-col lg:flex-row">
                    <div className="w-full lg:w-4/12 mr-12">
                        <h2 className='font-medium text-sm mb-2.5'>Webhook endpoints</h2>
                        <p className="text-sm">
                            Webhook allows external services to be notified when events happen within your account. When the specified events occur, a POST request will be sent to each of the Webhook endpoint
                        </p>
                    </div>
                    <div>
                        <div className="text-sm mb-2.5">You haven’t created any webhooks yet</div>
                        <PrimaryButton
                            position='prepend'
                            icon='/images/icons/rounded-plus.svg'
                            styling='bg-[#60579E] text-white text-sm w-[206px] h-[39px] flex justify-center items-center'
                            content='Add Webhook'
                        />
                    </div>
                </div>
            </SettingLayout>
        </div>
    )
}
export default WebhookSetting;