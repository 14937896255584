import React from "react";
import PrimaryButton from "../../common/buttons/PrimaryButton"

export default function ProductQuestion({ item }) {
    return (
        <div className="flex p-2">
            <img src="/images/profile-placeholder.svg" alt="user profile" className="h-[46px] w-[46px]" />
            <div className="w-[95%] ml-2">
                <div className="flex justify-between">
                    <div>
                        <div className="text-[15px] font-medium">{item.user.name}</div>
                        <div className="">
                            <div className="flex items-center">
                                <img src="/images/icons/Flag.svg" className='h-[12px] w-[16px]' alt="flag icon" />
                                <p className="text-sm ml-2">{item.user.country}</p>
                            </div>
                        </div>
                    </div>
                    <p className="text-[13px] text-[#828282]">{item.question.created_at}</p>
                </div>
                <div className="mt-1.5 flex">
                    <div className="font-medium text-[15px] text-[#828282]  w-[78px]">Questions:</div>
                    <p className="text-[#007185] text-[15px] w-[85%]">{item.question.questions}</p>
                </div>
                <div className="mt-1.5 flex">
                    <div className="font-medium text-[15px] text-[#828282] w-[78px] mr-1.5">Answer:</div>
                    <p className="text-black text-[15px] w-[85%]">{item.question.answer}</p>
                </div>
                <div className="text-[15px] mt-1.5 text-[#4F4F4F]">
                    {item.title}
                </div>
                <div className="flex justify-end items-center mt-1.5">
                    <p className="mr-2.5 text-[13px] text-[#4F4F4F]">100 people found this helpful</p>
                    <PrimaryButton
                        position="prepend"
                        icon="/images/icons/like.svg"
                        styling="border border-[#BDBDBD] rounded w-[149px] text-sm py-2.5 flex items-center justify-center"
                        content="Helpful"
                    />
                </div>
            </div>
        </div>
    )
}
