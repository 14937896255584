import React from "react";
import PrimaryButton from "../../common/buttons/PrimaryButton";

function ProductReview({ item }) {
    return (
        <div className="flex py-1 lg:p-2">
            <img src="/images/profile-placeholder.svg" alt="user profile" className="h-[46px] w-[46px]" />
            <div className="w-[95%] ml-2">
                <div className="flex justify-between">
                    <div>
                        <div className="text-[15px] font-medium">{item.user.name}</div>
                        <div className="">
                            <div className="flex items-center">
                                <img src="/images/icons/Flag.svg" className='h-[12px] w-[16px]' alt="flag icon" />
                                <p className="text-sm ml-2">{item.user.country}</p>
                            </div>
                        </div>
                    </div>
                    <p className="text-[13px] text-[#828282]">{item.review.created_at}</p>

                </div>
                <div className="flex items-start lg:items-center mt-3">
                    <div className="flex items-center mr-3">
                        {item.review.rating.map((item, index) => <img key={index} src="/images/full-star.svg" className="h-[15px] lg:h-[22px] w-[15px] lg:w-[22px]" />)}
                    </div>
                    <p className="font-medium text-sm lg:text-[15px] lg:mt-1 ml-2">{item.review.customer_review}</p>
                </div>
                <div className="mt-2.5 text-sm lg:text-[15px] text-[#4F4F4F]">
                    {item.review.description}
                </div>
                <div className="mt-1">
                    <img src={item.review.image} />
                </div>
                <div className="mt-1">
                    <p className='text-[13px] lg:text-[15px] text-[#4F4F4F]'>{item.review.title}</p>
                    <p className="text-[13px] lg:text-[15px] text-[#4F4F4F] font-bold"> Verified Purchase</p>
                </div>
                <div className="flex justify-end items-center mt-1.5">
                    <p className="mr-2.5 text-[13px] text-[#4F4F4F]">100 people found this helpful</p>
                    <PrimaryButton
                        position="prepend"
                        icon="/images/icons/like.svg"
                        styling="border border-[#BDBDBD] rounded w-[149px] text-sm py-2.5 flex items-center justify-center"
                        content="Helpful"
                    />
                </div>
            </div>
        </div>

    )
}

export default ProductReview