const escapeRegExp = (s) => {
	return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const ltrim = (str, character = " ") => {
	const re = new RegExp("^" + escapeRegExp(character) + "+", "g");
	return str.replace(re, "");
};

export const rtrim = (str, character = " ") => {
	const re = new RegExp(escapeRegExp(character) + "+$", "g");
	return str.replace(re, "");
};

export const CheckAlphaNumSpecial = (text) => {
	const regex = new RegExp(
		/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-/" -]+$/g
	);
	if (regex.test(text.trim())) {
		return true;
	}
	return false;
};

export const alphabetColors = {
	a: "hsl(0, 60%, 70%)", // Red with lower saturation
	b: "hsl(120, 60%, 70%)", // Green with lower saturation
	c: "hsl(240, 60%, 70%)", // Blue with lower saturation
	d: "hsl(60, 60%, 70%)", // Yellow with lower saturation
	e: "hsl(300, 60%, 70%)", // Magenta with lower saturation
	f: "hsl(180, 60%, 70%)", // Cyan with lower saturation
	g: "hsl(30, 60%, 70%)", // Orange with lower saturation
	h: "hsl(270, 60%, 70%)", // Purple with lower saturation
	i: "hsl(90, 60%, 70%)", // Lime with lower saturation
	j: "hsl(210, 60%, 70%)", // Navy with lower saturation
	k: "hsl(330, 60%, 70%)", // Pink with lower saturation
	l: "hsl(150, 60%, 70%)", // Sky Blue with lower saturation
	m: "hsl(45, 60%, 70%)", // Peach with lower saturation
	n: "hsl(225, 60%, 70%)", // Violet with lower saturation
	o: "hsl(135, 60%, 70%)", // Teal with lower saturation
	p: "hsl(15, 60%, 70%)", // Maroon with lower saturation
	q: "hsl(105, 60%, 70%)", // Forest Green with lower saturation
	r: "hsl(75, 60%, 70%)", // Brown with lower saturation
	s: "hsl(285, 60%, 70%)", // Plum with lower saturation
	t: "hsl(195, 60%, 70%)", // Turquoise with lower saturation
	u: "hsl(45, 60%, 70%)", // Gold with lower saturation
	v: "hsl(25, 60%, 70%)", // Sienna with lower saturation
	w: "hsl(105, 60%, 70%)", // Olive Green with lower saturation
	x: "hsl(315, 60%, 70%)", // Rose with lower saturation
	y: "hsl(255, 60%, 70%)", // Lavender with lower saturation
	z: "hsl(165, 60%, 70%)", // Light Blue with lower saturation
};

export const capitalizeString = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};