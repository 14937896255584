import React from "react";
import PrimaryButton from "../common/buttons/PrimaryButton";
import PrimaryInput from "../common/inputs/PrimaryInput";

const GeneralSetting = () => {
    return (
        <form action="">
            <div className="border-b border-[#BDBDBD]  p-6">
                <div className="p-6 flex flex-col lg:flex-row justify-between ">
                    <div className="w-full  lg:w-[25%]">
                        <div className="text-sm font-medium">
                            General Email Settings
                        </div>
                        <div className="mt-3 text-sm">
                            Set your from email, subject and other options
                        </div>
                    </div>
                    <div className="w-full lg:w-[70%]">
                        <div className=" flex flex-col lg:flex-row justify-between">
                            <div className="w-full lg:w-[48%]">
                                <PrimaryInput
                                    label='Store URL'
                                    type="text"
                                    placeholder='Enter Store URL'
                                    width='w-full'
                                />
                            </div>
                            <div className="w-full lg:w-[48%]">
                                <PrimaryInput
                                    label='Store Name'
                                    type="text"
                                    placeholder='Enter Store Name'
                                    width='w-full'
                                />
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between mt-6">
                            <div className="w-full lg:w-[48%]">
                                <PrimaryInput
                                    label='From Email'
                                    type="email"
                                    placeholder='Enter from email'
                                    width='w-full'
                                />
                            </div>
                            <div className="w-full lg:w-[48%]">
                                <PrimaryInput
                                    label='From Name'
                                    type="text"
                                    placeholder='Enter from  Name'
                                    width='w-full'
                                />
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between mt-6">
                            <div className="w-full lg:w-[48%]">
                                <PrimaryInput
                                    label='Reply To Email'
                                    type="text"
                                    placeholder='Enter reply to email'
                                    width='w-full'
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="border-b border-[#BDBDBD]">
                <div className="p-6 flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-[25%]">
                        <div className="text-sm font-medium">
                            General Logo Settings
                        </div>
                        <div className="mt-3 text-sm">
                            Add or Edit your from Logo
                        </div>
                    </div>
                    <div className="w-full lg:w-[70%]">
                        <div>
                            <img src="/images/inspireuplift-logo.svg" height="108" width="209" alt="" />
                        </div>
                        <div className="mt-3">
                            <PrimaryButton
                                type="button"
                                position='prepend'
                                icon='/images/icons/black-rounded-plus.svg'
                                content='Add a logo'
                                width='w-full hover:bg-[#F6F6F6]'
                                styling='flex justify-center items-center  bg-transparent w-[183px] h-[40px] border border-[#BDBDBD] rounded text-sm hover:bg-[#F6F6F6]'
                            />
                        </div>
                        <div className="mt-3 w-full lg:w-[48%]">
                            <p className="text-sm mb-1">Logo Image Width (in px, recommended 450 or below)</p>
                            <PrimaryInput
                                type="text"
                                placeholder='Enter logo width'
                                width='w-full'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-6 flex flex-col lg:flex-row items-center  lg:justify-end">
                <div className="mb-3 lg:mb-0 lg:mr-3">
                    <PrimaryButton
                        position='prepend'
                        icon='/images/icons/delete.svg'
                        content='Discard'
                        styling='flex justify-center items-center  bg-transparent w-[183px] h-[40px] border border-[#BDBDBD] rounded text-sm hover:bg-[#F6F6F6]'
                    />
                </div>
                <div>
                    <PrimaryButton
                        position='prepend'
                        icon='/images/icons/save-btn.svg'
                        content='Save'
                        styling='flex justify-center items-center  bg-[#60579E] text-white w-[183px] h-[40px] border border-[#BDBDBD] rounded text-sm'
                    />
                </div>
            </div>
        </form>
    )
}
export default GeneralSetting;