import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../buttons/PrimaryButton";
import { useSelector } from "react-redux";

const ActionNav = ({
	handleArchive,
	status,
	archiveStatus,
	title,
	handleApprove,
	date,
	publishedStatus,
	path,
}) => {
	const navigate = useNavigate();
	const auth = useSelector((state) => state.auth);
	return (
		<ul className={`action-nav-container overflow-auto ${(status === 0 || status === 2 || status == 4 ) ? "block sm:flex":"flex"}  justify-between items-center sm:h-[55px] py-2 px-6`}>
			<div className="flex items-center h-full ">
				<li
					onClick={() => navigate(-1)}
					className={` text-sm lg:text-base h-full   flex items-center justify-center px-2 cursor-pointer`}
				>
					<img
						src="/images/icons/left.svg"
						alt="go back icon"
						className="h-3.5"
					/>
				</li>

				<li className="w-0.5 bg-[#D9D9D9] mx-4 h-7 "></li>
				<li
					className={` text-xl font-medium text-[#232323] h-full flex items-center justify-center flex-col sm:flex-row px-2`}
				>
					{title}
					<span className="text-[#828282] text-sm ml-2">{date}</span>
				</li>
			</div>
			<div className={`flex ${(status === 0 || status === 2 || status == 4 )? "justify-between sm:justify-start"  :""}  sm:items-center h-full items-end mt-4 sm:mt-0`}>
				{path === "question" && status !== archiveStatus ? (
					<li
						onClick={handleArchive}
						className="text-sm lg:text-base   h-full  flex items-center justify-center cursor-pointer mb-3 sm:mb-0"
					>
						<img
							alt="delete icon"
							src="/images/icons/archive.svg"
						/>
					</li>
				) : null}
				{path === "question" &&
				status !== publishedStatus &&
				status !== 4 ? (
					<li className={`w-0.5 bg-[#D9D9D9] mx-4 h-7 ${(status === 0 || status === 2 || status == 4 ) ? "hidden sm:block" : "block"}`}></li>
				) : null}

				{path === "question" &&
				(status === 0 || status === 2 || status == 4) ? (
					<PrimaryButton
						handleClick={handleApprove}
						position="prepend"
						icon="/images/icons/upward-arrow-white.svg"
						styling="w-[165px] rounded py-2 bg-[#60579E] text-white flex items-center justify-center ml-auto"
						content="Publish"
						type="button"
					/>
				) : null}

				{path === "review" &&
				status !== archiveStatus &&
				(auth?.user?.store_ids?.[0] <= 9 ||
					auth?.user?.is_admin === true) ? (
					<li
						onClick={handleArchive}
						className="text-sm lg:text-base   h-full  flex items-center justify-center cursor-pointer "
					>
						<img
							alt="delete icon"
							src="/images/icons/archive.svg"
						/>
					</li>
				) : null}
				{path === "review" &&
				status !== publishedStatus &&
				status !== archiveStatus &&
				(auth?.user?.store_ids?.[0] <= 9 ||
					auth?.user?.is_admin === true) ? (
					<li className="w-0.5 bg-[#D9D9D9] mx-4 h-7 "></li>
				) : null}
				<li>
					{path === "review" &&
					(status === 0 || status === 2 || status == 4) &&
					(auth?.user?.store_ids?.[0] <= 9 ||
						auth?.user?.is_admin === true) ? (
						<PrimaryButton
							handleClick={handleApprove}
							position="prepend"
							icon="/images/icons/upward-arrow-white.svg"
							styling="w-[165px] rounded py-2 bg-[#60579E] text-white flex items-center justify-center"
							content="Publish"
							type="button"
						/>
					) : null}
				</li>
			</div>

			{/* <li className="border text-sm  lg:text-base border-[#BDBDBD]  h-full  flex items-center justify-center px-2"><span className="pr-1 lg:pr-3">Delete</span> <img src="/images/icons/delete.svg" /></li>
            <li className="border text-sm  lg:text-base border-[#BDBDBD]  h-full    flex items-center justify-center px-2"><span className="pr-1 lg:pr-3">Mark as Unread</span> <img src="/images/icons/unread-message.svg" /></li> */}
		</ul>
	);
};
export default ActionNav;
