import { siteApiUrl } from "../config";
import axiosInstance from "./axiosInstance";
import { getApi } from "./BaseService";

// GET

export function getUserInfo() {
	return getApi({
		url: "userinfo",
	}).then((res) => res.data);
}

// POST

export function loginUser(data) {
	return axiosInstance({
		method: "POST",
		url: `${siteApiUrl}login`,
		data: {
			...data,
		},
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
	});
}

export function verifyUser(token) {
	return axiosInstance({
		method: "post",
		url: `${siteApiUrl}user-verification`,
		data: {
			token,
		},

		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
	});
};

