import React, { useState } from "react";
import "../../../styles/ImagePreview.css";

const ImagePreview = ({ images, onClose, selectedIndex }) => {
	const [currentIndex, setCurrentIndex] = useState(selectedIndex);

	const goToNext = () => {
		if (currentIndex < images.length - 1) {
			setCurrentIndex(currentIndex + 1);
		}
	};

	const goToPrevious = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	};
	return (
		<div className="slider">
			<button className="slider__close-button" onClick={onClose}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					width="32"
					height="32"
					aria-hidden="true"
					focusable="false"
				>
					<g fill="#ffffff">
						<path d="M0 0h24v24H0z" fill="none"></path>
						<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
					</g>
				</svg>
			</button>
			<div className="slider__image--container">
				<img alt="slider image"  className="slider__image" src={images[currentIndex]} />
			</div>
			<div className="absolute inset-y-1/2 flex justify-between w-full px-4">
				<button
					onClick={goToPrevious}
					disabled={currentIndex === 0}
                    className="slider__button slider__button-pre"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
						aria-hidden="true"
						focusable="false"
						className="h-8 w-8"
					>
						<g fill="#fff">
							<path d="M0 0h24v24H0z" fill="none"></path>
							<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
						</g>
					</svg>
				</button>
				<button
					onClick={goToNext}
					disabled={currentIndex === images.length - 1}
                    className="slider__button slider__button--next"
                        
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
						aria-hidden="true"
						focusable="false"
						className="h-8 w-8"
					>
						<g fill="#fff">
							<path d="M0 0h24v24H0z" fill="none"></path>
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
						</g>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default ImagePreview;
