import React from "react";
import PrimaryPopupWrapper from '../common/popup/PrimaryPopup';
import PrimaryInput from '../common/inputs/PrimaryInput';
import PrimaryButton from '../common/buttons/PrimaryButton';

const AddBalancePopup = ({ handleAddBalance }) => {
    return (
        <PrimaryPopupWrapper title="Add Balance" handleClosePopup={handleAddBalance}>
            <div>
                <div className="border-b border-[#BDBDBD] p-6">
                    <PrimaryInput
                        label="Enter Amount"
                        placeholder="$ Enter Amount"
                        width="w-full"
                    />
                </div>
                <div className="p-6 flex items-center justify-end">
                    <PrimaryButton
                        position='prepend'
                        icon='/images/icons/rounded-plus.svg'
                        content='Add'
                        styling='flex justify-center items-center  bg-[#60579E] text-white w-[150px] h-[40px] border border-[#BDBDBD] rounded text-sm'
                    />
                </div>
            </div>
        </PrimaryPopupWrapper>
    )

}
export default AddBalancePopup;