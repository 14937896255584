import React from "react";
const PrimaryButton = (
    {
        position,
        icon,
        content,
        styling,
        iconStyling,
        handleClick,
        type,
        isDisabled,
        id,
        iconClassName,
        textStyle="ml-2"

    }
) => {
    return (
        <button id={id} className={`${styling}`} disabled={isDisabled} type={type} onClick={handleClick}>
            {position == "prepend" ? (
                <img src={`${icon}`} alt="btn icon" className={`${iconClassName}`} />
            ) : ''}
            <span className={`text-sm font-normal inline-block ${textStyle ? textStyle : ""}`}>{content}</span>
            {position == "postpend" ? (
                <img src={`${icon}`} className={`${iconStyling}`} alt="btn icon" />
            ) : ''}
        </button>
    )
}

export default PrimaryButton;