import React from "react";
const PrimaryRadio = ({
    label,
    id,
    isChecked,
    name,

}) => {
    return (
        <div className="kch-box flex items-center cursor-pointer">
            <input type="radio" id={id} checked={isChecked} name={name} className="w-4 h-4 opacity-0 cursor-pointer" />
            <label htmlFor={id} className="input-text-color text-sm cursor-pointer ml-2 mt-1">{label}</label>
            <div className="absolute p-1 mt-1 bg-white border-2 border-gray-500 rounded-full pointer-events-none cursor-pointer">
                <div className="custom-check invisible w-2 h-2 bg-gray-500 rounded-full"></div>
            </div>
        </div>
    )
}
export default PrimaryRadio;