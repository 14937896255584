import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

createTheme("default", {
	text: {
		primary: "#000",
		secondary: "#000",
	},
	background: {
		default: "transparent",
	},
	context: {
		background: "#f8d7da",
		text: "#000",
	},
	divider: {
		default: "rgba(0, 0, 0, 0.25)",
	},
});

const customStyles = {
	cells: {
		style: {
			fontSize: "14px",
			fontWeight: "400",
		},
	},
};

const PrimaryTable = ({
	data,
	columns,
	selectable,
	rowUrl,
	ignoreRowClick,
	setSelectedRows,
	selectableRowsComponent,
	handleDelete,
	pointerOnHover,
	conditionalRowStyles
}) => {
	const [headerTitle, setHeaderTitle] = useState("");
	const history = useNavigate();

	const location = useLocation();

	const handleRowclick = (item) => {
		if (rowUrl && rowUrl !== "undefined") {
			let id = item.product.props.id;
			if (rowUrl === "/questions") {
				history(
					`${rowUrl}/${item.product.props.question_id}?type=${location?.search}`
				);
			} else if (rowUrl === "/reviews") {
				history(`${rowUrl}/${id}${location?.search}`);
			} else {
				history(`${rowUrl}/${id}`);
			}
		}
	};

	const contextActionButton = (selected) => {
		const path = location.pathname;
		if (path.includes("review-request")) {
			return (
				<div className="flex">
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center"
					>
						sent
						<img src="/images/icons/submit.svg" className="ml-2" />
					</button>
				</div>
			);
		} else if (path.includes("questions")) {
			return (
				<div className="flex">
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						<span className="mr-2">Publish</span>
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19 12.7002C18.4 12.7002 18 13.1002 18 13.7002V17.7002C18 18.0002 17.9 18.2002 17.7 18.4002C17.5 18.6002 17.3 18.7002 17 18.7002H3C2.7 18.7002 2.5 18.6002 2.3 18.4002C2.1 18.2002 2 18.0002 2 17.7002V13.7002C2 13.1002 1.6 12.7002 1 12.7002C0.4 12.7002 0 13.1002 0 13.7002V17.7002C0 18.5002 0.3 19.3002 0.9 19.8002C1.4 20.4002 2.2 20.7002 3 20.7002H17C17.8 20.7002 18.6 20.4002 19.1 19.8002C19.6 19.2002 20 18.5002 20 17.7002V13.7002C20 13.1002 19.6 12.7002 19 12.7002ZM5.7 7.4002L9 4.1002V13.7002C9 14.3002 9.4 14.7002 10 14.7002C10.6 14.7002 11 14.3002 11 13.7002V4.1002L14.3 7.4002C14.5 7.60019 14.7 7.7002 15 7.7002C15.3 7.7002 15.5 7.60019 15.7 7.4002C16.1 7.0002 16.1 6.4002 15.7 6.0002L10.7 1.0002C10.6 0.900195 10.5 0.800195 10.4 0.800195C10.3 0.700195 10.1 0.700195 10 0.700195C9.9 0.700195 9.7 0.700195 9.6 0.800195C9.5 0.800195 9.4 0.900195 9.3 1.0002L4.3 6.0002C3.9 6.4002 3.9 7.0002 4.3 7.4002C4.7 7.8002 5.3 7.8002 5.7 7.4002Z"
								fill="#bdbdbd"
							/>
						</svg>
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						<span className="mr-2">Unpublish</span>
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19 12.7002C18.4 12.7002 18 13.1002 18 13.7002V17.7002C18 18.0002 17.9 18.2002 17.7 18.4002C17.5 18.6002 17.3 18.7002 17 18.7002H3C2.7 18.7002 2.5 18.6002 2.3 18.4002C2.1 18.2002 2 18.0002 2 17.7002V13.7002C2 13.1002 1.6 12.7002 1 12.7002C0.4 12.7002 0 13.1002 0 13.7002V17.7002C0 18.5002 0.3 19.3002 0.9 19.8002C1.4 20.4002 2.2 20.7002 3 20.7002H17C17.8 20.7002 18.6 20.4002 19.1 19.8002C19.6 19.2002 20 18.5002 20 17.7002V13.7002C20 13.1002 19.6 12.7002 19 12.7002ZM5.7 7.4002L9 4.1002V13.7002C9 14.3002 9.4 14.7002 10 14.7002C10.6 14.7002 11 14.3002 11 13.7002V4.1002L14.3 7.4002C14.5 7.60019 14.7 7.7002 15 7.7002C15.3 7.7002 15.5 7.60019 15.7 7.4002C16.1 7.0002 16.1 6.4002 15.7 6.0002L10.7 1.0002C10.6 0.900195 10.5 0.800195 10.4 0.800195C10.3 0.700195 10.1 0.700195 10 0.700195C9.9 0.700195 9.7 0.700195 9.6 0.800195C9.5 0.800195 9.4 0.900195 9.3 1.0002L4.3 6.0002C3.9 6.4002 3.9 7.0002 4.3 7.4002C4.7 7.8002 5.3 7.8002 5.7 7.4002Z"
								fill="#bdbdbd"
							/>
						</svg>
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						Archive
						<img src="/images/icons/archive.svg" className="ml-2" />
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center"
					>
						Delete
						<img src="/images/icons/delete.svg" className="ml-2" />
					</button>
				</div>
			);
		} else if (path.includes("reviews")) {
			return (
				<div className="flex">
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						<span className="mr-2">Publish</span>
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19 12.7002C18.4 12.7002 18 13.1002 18 13.7002V17.7002C18 18.0002 17.9 18.2002 17.7 18.4002C17.5 18.6002 17.3 18.7002 17 18.7002H3C2.7 18.7002 2.5 18.6002 2.3 18.4002C2.1 18.2002 2 18.0002 2 17.7002V13.7002C2 13.1002 1.6 12.7002 1 12.7002C0.4 12.7002 0 13.1002 0 13.7002V17.7002C0 18.5002 0.3 19.3002 0.9 19.8002C1.4 20.4002 2.2 20.7002 3 20.7002H17C17.8 20.7002 18.6 20.4002 19.1 19.8002C19.6 19.2002 20 18.5002 20 17.7002V13.7002C20 13.1002 19.6 12.7002 19 12.7002ZM5.7 7.4002L9 4.1002V13.7002C9 14.3002 9.4 14.7002 10 14.7002C10.6 14.7002 11 14.3002 11 13.7002V4.1002L14.3 7.4002C14.5 7.60019 14.7 7.7002 15 7.7002C15.3 7.7002 15.5 7.60019 15.7 7.4002C16.1 7.0002 16.1 6.4002 15.7 6.0002L10.7 1.0002C10.6 0.900195 10.5 0.800195 10.4 0.800195C10.3 0.700195 10.1 0.700195 10 0.700195C9.9 0.700195 9.7 0.700195 9.6 0.800195C9.5 0.800195 9.4 0.900195 9.3 1.0002L4.3 6.0002C3.9 6.4002 3.9 7.0002 4.3 7.4002C4.7 7.8002 5.3 7.8002 5.7 7.4002Z"
								fill="#bdbdbd"
							/>
						</svg>
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						<span className="mr-2">Unpublish</span>
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19 12.7002C18.4 12.7002 18 13.1002 18 13.7002V17.7002C18 18.0002 17.9 18.2002 17.7 18.4002C17.5 18.6002 17.3 18.7002 17 18.7002H3C2.7 18.7002 2.5 18.6002 2.3 18.4002C2.1 18.2002 2 18.0002 2 17.7002V13.7002C2 13.1002 1.6 12.7002 1 12.7002C0.4 12.7002 0 13.1002 0 13.7002V17.7002C0 18.5002 0.3 19.3002 0.9 19.8002C1.4 20.4002 2.2 20.7002 3 20.7002H17C17.8 20.7002 18.6 20.4002 19.1 19.8002C19.6 19.2002 20 18.5002 20 17.7002V13.7002C20 13.1002 19.6 12.7002 19 12.7002ZM5.7 7.4002L9 4.1002V13.7002C9 14.3002 9.4 14.7002 10 14.7002C10.6 14.7002 11 14.3002 11 13.7002V4.1002L14.3 7.4002C14.5 7.60019 14.7 7.7002 15 7.7002C15.3 7.7002 15.5 7.60019 15.7 7.4002C16.1 7.0002 16.1 6.4002 15.7 6.0002L10.7 1.0002C10.6 0.900195 10.5 0.800195 10.4 0.800195C10.3 0.700195 10.1 0.700195 10 0.700195C9.9 0.700195 9.7 0.700195 9.6 0.800195C9.5 0.800195 9.4 0.900195 9.3 1.0002L4.3 6.0002C3.9 6.4002 3.9 7.0002 4.3 7.4002C4.7 7.8002 5.3 7.8002 5.7 7.4002Z"
								fill="#bdbdbd"
							/>
						</svg>
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						Archive
						<img src="/images/icons/archive.svg" className="ml-2" />
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3"
					>
						Delete
						<img src="/images/icons/delete.svg" className="ml-2" />
					</button>
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center"
					>
						Mark as Read
						<img
							src="/images/icons/unread-message.svg"
							className="ml-2"
						/>
					</button>
				</div>
			);
		} else {
			return (
				<div className="flex">
					{/* <button onClick={handleDelete} className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center mr-3">
                        Delete
                        <img src="/images/icons/delete.svg" className="ml-2" />
                    </button> */}
					<button
						onClick={handleDelete}
						className="border border-[#BDBDBD] rounded py-1.5 px-2.5 text-sm flex items-center"
					>
						send
						<img src="/images/icons/submit.svg" className="ml-2" />
					</button>
				</div>
			);
		}
	};

	const onSelectedRowsChange = ({ selectedRows }) => {
		if (selectedRows.length > 0) {
			setHeaderTitle("Reviews");
		} else {
			setHeaderTitle("");
		}
	};

	return (
		<DataTable
			title={headerTitle}
			theme="default"
			data={data}
			columns={columns}
			customStyles={customStyles}
			selectableRows={selectable}
			onRowClicked={handleRowclick}
			contextActions={contextActionButton()}
			ignoreRowClick={ignoreRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			pointerOnHover={pointerOnHover}
			conditionalRowStyles={conditionalRowStyles}
		/>
	);
};

export default PrimaryTable;
