import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { frontUrl } from "../../../config";

const PrimarySidebar = ({ showSidebar, handleShowSidebar }) => {
	const location = useLocation();
	const history = useNavigate();
	const [expandIndex, setExpandIndex] = useState(null);
	const [isExpand, setExpand] = useState(false);

	const sidebarLinks = [
		{
			name: "Dashboard",
			link: "/dashboard",
			icon: "/images/icons/home.svg",
		},
		{
			name: "Review Requests",
			link: "/review-request?type=pending",
			icon: "/images/icons/queue.svg",

			// SubLinks for future (Warning: Do not remove)
			//sublinkIcon: "/images/icons/rectangle.svg",
			// sublinks: [
			//     {
			//         name: "Review Requests",
			//         link: "/queue?type=pending"
			//     }
			// ]
		},

		// Products and Customers Section (Warning: Do not remove)
		// {
		//     name: "Products",
		//     link: "/products",
		//     icon: "/images/icons/product.svg",
		// },
		// {
		//     name: "Customers",
		//     link: "/customers",
		//     icon: "/images/icons/customer.svg"
		// },

		{
			name: "Reviews",
			link: "/reviews?type=published",
			icon: "/images/icons/review.svg",
		},
		{
			name: "Questions",
			link: "/questions?type=published",
			icon: "/images/icons/question.svg",
		},

		// Widgets and Settings Section (Warning: Do not remove)
		// {
		//     name: "Widgets",
		//     link: "/widgets/main",
		//     icon: "/images/icons/widgets.svg",
		//     sublinkIcon: "/images/icons/rectangle.svg",
		//     sublinks: [
		//         {
		//             name: "Main",
		//             link: "/widgets/main"
		//         },
		//         // {
		//         //     name: "Display",
		//         //     link: "/widgets/display"
		//         // },
		//         // {
		//         //     name: 'Image',
		//         //     link: "/widgets/image"
		//         // },
		//         // {
		//         //     name: 'Form',
		//         //     link: "/widgets/form"
		//         // },
		//     ]
		// },
		// {
		//     name: "Settings",
		//     link: "/settings",
		//     icon: "/images/icons/setting.svg"
		// }
	];

	useEffect(() => {
		const activeIndex = sidebarLinks.findIndex((item) => {
			const link = new URL(item.link, frontUrl).pathname;
			return link === location.pathname;
		});

		setExpandIndex(activeIndex);
		setExpand(true);
	}, [location.pathname]);

	const handleExpand = (e, index, link) => {
		history(link);
		setExpandIndex(index);
		setExpand(true);
		handleShowSidebar();
	};

	return (
		<div className="">
			<nav
				className={` fixed h-full top-0 left-0 p-0 nav-bg z-50 md:w-auto bg-[#3B3561] max-w-[260px] ${
					showSidebar ? "active" : ""
				}`}
				id="nav-menu"
			>
				<div
					className="app-logo h-auto w-auto m-auto  2xl:h-auto py-4 hidden md:flex justify-center items-center cursor-pointer"
					onClick={() => history("/dashboard")}
				>
					<img
						src="/images/site-logo.svg"
						alt="site logo"
						className="w-[180px]"
						// height="35"
						// width="146"
					/>
				</div>

				<ul className="nav-links">
					{sidebarLinks.map((item, index) => {
						let activeLink = expandIndex == index && isExpand;
						return (
							<div key={index}>
								<div className="relative">
									<li
										className={`nav-link relative flex items-center  pl-5 pr-5 py-3.5 ${
											activeLink ? "bg-[#60579E]" : ""
										}  text-white text-base cursor-pointer`}
										onClick={(e) =>
											handleExpand(e, index, item.link)
										}
									>
										<a className={"flex items-center"}>
											<span>
												<img src={item.icon} />
											</span>
											<span className=" ml-5 text-[15px] mr-8 capitalize hidden md:inline">
												{item.name}
											</span>
										</a>
										{item.sublinks?.length > 0 && (
											<span
												className={`hidden md:inline transition ${
													expandIndex === index &&
													isExpand &&
													"rotate-180"
												}`}
											>
												<img src="/images/icons/rectangle.svg" />
											</span>
										)}
									</li>
									<li
										className={`
                                            block h-0 transition delay-200 overflow-hidden  opacity-0 ml-5 md:bg-transparent sub-menu-container font-bold
                                            ${
												expandIndex === index &&
												isExpand &&
												"!h-auto !overflow-auto !opacity-100 "
											}
                                        `}
									>
										{item?.sublinks?.map((item, index) => {
											return (
												<div
													key={index}
													className="nav-sublink-box nav-sublink-box-products"
												>
													<Link to={item.link}>
														<a
															className={`relative nav-sublink flex justify-start md:justify-center items-center text-white text-sm pl-6 md:pl-9 pr-0 py-3.5 border-l-4 border-transparent nav-link-hover-bg w-full`}
														>
															<span
																className={`w-0 md:w-32 capitalize text-xs md:text-sm inline-block relative navlink-innertext`}
															>
																{item.name}
															</span>
														</a>
													</Link>
												</div>
											);
										})}
									</li>
								</div>
							</div>
						);
					})}
				</ul>
				<div
					className={`expandBtn-box absolute flex md:hidden justify-center items-center ${showSidebar ? "-right-[1.5rem] " : "-right-[5.5rem] "} z-40  cursor-pointer top-[90px]`}
				>
						{showSidebar && (
						<span
							className="unexpand-btn"
							onClick={handleShowSidebar}
						>
							<svg
								width="70"
								height="20"
								viewBox="0 0 70 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									width="70"
									height="20"
									rx="3"
									fill="#3B3561"
								/>
								<path
									d="M56 10L63.5 4.80385L63.5 15.1962L56 10Z"
									fill="white"
								/>
								<rect
									x="54"
									y="14"
									width="2"
									height="8"
									transform="rotate(-180 54 14)"
									fill="white"
								/>
							</svg>
						</span>
					)}

					{!showSidebar && (
						<span
							className="expand-btn"
							onClick={handleShowSidebar}
						>
							<svg
								width="70"
								height="20"
								viewBox="0 0 70 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									width="70"
									height="20"
									rx="3"
									fill="#3B3561"
								/>
								<path
									d="M62 10L54.5 15.1962L54.5 4.80385L62 10Z"
									fill="white"
								/>
								<rect
									x="64"
									y="6"
									width="2"
									height="8"
									fill="white"
								/>
							</svg>
						</span>
					)}
				</div>
			</nav>
		</div>
	);
};
export default PrimarySidebar;
