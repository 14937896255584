import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { alphabetColors } from "../../utils/string";

const Header = ({ handleExpand }) => {
	const authState = useSelector((state) => state.auth);
	const [showDropDown, setShowDropdown] = useState(false);

	const handleDropDown = () => {
		setShowDropdown(!showDropDown);
	};

	const { isLoggedIn, user } = authState;
	return (
		<header className="header-section !h-[60px] lg:h-[10vh]  2xl:h-[6vh] flex justify-end items-center w-full relative">
			{/* <Link to={"/dashboard"}>
				<div className="block lg:hidden ml-3">
					<img
						src="/images/site-logo.svg"
						alt="Reviewits logo"
						className="w-full m-auto"
						height="35"
						width="146"
					/>
				</div>
			</Link> */}

			<div
				className="flex items-center mr-6"
				// onClick={handleDropDown}
			>
				<div className="flex flex-col ml-4">
					<span className="text-[#232323] text-[15px] mr-1 capitalize">
						{user?.store_name}
					</span>
					<span className="text-[#828282] text-[13px] leading-[1px]">
						Inspire Uplift
					</span>
				</div>
				<div className="mr-2 ">
					<div
						className="min-w-[35px] min-h-[35px] flex items-center justify-center rounded-full mix-blend-difference font-semibold"
						style={{
							background:
								alphabetColors?.[
									user?.name?.[0]?.toLowerCase?.()
								],
						}}
					>
						{user?.store_name?.[0]}
					</div>
				</div>
				{/* Dropdown icon */}
				{/* <div className="cursor-pointer">
                    <img src="/images/icons/rectangle.svg" className={`relative ${showDropDown ? "rotate-180" : ""}`} alt="rectangle icon" />
                </div> */}
			</div>
			{/* {
                showDropDown ? (
                    <>
                        <div className="fixed z-10 h-full w-full top-0" onClick={handleDropDown}></div>
                        <div className="absolute text-[#828282] bg-white w-[200px] bottom-[-91px] right-4 drop-shadow-md  rounded text-sm p-4 z-20">
                            <div className="border-b border-[#BDBDBD] pb-2">
                                <Link to={"/settings"}>
                                    <a className="hover:text-black">
                                        Account settings
                                    </a>
                                </Link>

                            </div>
                            <div className="pt-2">
                                <span className="cursor-pointer hover:text-black">Logout</span>
                            </div>
                        </div>
                    </>
                ) : null
            } */}
		</header>
	);
};

export default Header;
