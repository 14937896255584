import React from "react";
const PrimaryHeading = ({ heading, icons, buttons }) => {
	return (
		<div className="flex justify-between items-center">
			<h1 className="text-lg font-semibold">{heading}</h1>
			{buttons && (
				<button className={`flex items-center`}>
					{buttons.map((item, index) => {
						return (
							<div
								key={index}
								className={`flex items-center ${
									buttons.length > 1 ? "mr-6" : "mr-0"
								} ${
									item.length > 1 && item[item.length - 1]
										? "mr-0"
										: ""
								}`}
							>
								<img
									src={item.icon}
									height="20"
									width="20"
									alt="heading icon"
								/>
								<p className="ml-[6px] text-[15px] font-medium">
									{item.title}
								</p>
							</div>
						);
					})}
				</button>
			)}
		</div>
	);
};
export default PrimaryHeading;
