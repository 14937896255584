import React from "react";
import { useState } from 'react';

import PrimaryInput from '../../common/inputs/PrimaryInput';
import SwitchInput from '../../common/inputs/SwitchInput';
import PrimaryButton from '../../common/buttons/PrimaryButton';

function CustomizationsForm() {
    const [isShowAllOptions, setShowAllOptions] = useState(false);

    const handleOptionsShow = () => {
        setShowAllOptions(!isShowAllOptions);
    }

    return (
        <form className="bg-white body-shadow  rounded">
            <div className="p-6 border-b border-[#BDBDBD]">
                <div className="font-medium text-sm">
                    Customizations
                </div>
                <div className="mt-2.5">
                    <label className="text-sm text-black block mb-1">Language</label>
                    <select className="border border-[#BDBDBD] rounded text-[13px] px-3.5 py-2.5 w-full bg-transparent">
                        <option>English</option>
                    </select>
                </div>
                <div className="flex justify-between mt-2.5">
                    <div className="w-[48%]">
                        <label className="text-sm text-black block mb-1">Sort Type</label>
                        <select className="border border-[#BDBDBD] rounded px-3.5 py-2.5 w-full bg-transparent text-[13px]">
                            <option>Most Recent</option>
                        </select>
                    </div>
                    <div className="w-[48%]">
                        <label className="text-sm text-black block mb-1">Loading Type</label>
                        <select className="border border-[#BDBDBD] rounded px-3.5 py-2.5 w-full bg-transparent text-[13px]">
                            <option>Pagination</option>
                        </select>
                    </div>
                </div>
                <div className="mt-2.5">
                    <PrimaryInput
                        label="Review Per Page"
                        width="w-full"
                        placeholder="Enter reviews per page"
                    />
                </div>
                <p className="mt-2.5 text-[#2D9CDB] text-center text-[13px] cursor-pointer" onClick={handleOptionsShow}>
                    {isShowAllOptions ? "show less options" : 'show all options'}
                </p>

                {
                    isShowAllOptions ? (
                        <div className="mt-2.5">
                            <div className="flex justify-between items-center">
                                <div className="w-[48%]">
                                    <label className="text-sm text-black block mb-1">Date Format</label>
                                    <select className="border border-[#BDBDBD] rounded px-3.5 py-2.5 w-full bg-transparent text-[13px]">
                                        <option>dd/mm/yyyy</option>
                                    </select>
                                </div>
                                <div className="w-[48%]">
                                    <PrimaryInput
                                        label="Limit Words"
                                        width="w-full"
                                        placeholder="Enter Limit Words"
                                    />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Graph" />
                                </div>
                                <div className="w-[48%]">
                                    <SwitchInput label="Photo Summary" />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Sort Options" />
                                </div>
                                <div className="w-[48%]">
                                    <SwitchInput label="Recomended Percentage Summary" />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Reviews Tab" />
                                </div>
                                <div className="w-[48%]">
                                    <SwitchInput label="Customer Avatar" />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Customer's Location" />
                                </div>
                                <div className="w-[48%]">
                                    <SwitchInput label="Verified Purchase" />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Date" />
                                </div>
                                <div className="w-[48%]">
                                    <SwitchInput label="Share Icons" />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Vote Icons" />
                                </div>
                                <div className="w-[48%]">
                                    <SwitchInput label="Questions & Answers" />
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="w-[48%]">
                                    <SwitchInput label="Product & Varient" />
                                </div>
                            </div>


                        </div>
                    ) : null
                }

            </div>
            <div className="p-6 border-b border-[#BDBDBD]">
                <div className="font-medium text-sm">
                    Layout Design
                </div>
                <div className="flex flex-wrap mt-5">
                    <div className="min-h-[126px] border border-[#BDBDBD] mr-3 p-2.5 rounded layout-design-container layout-design-item">
                        <div className="">
                            <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] mb-2"></div>
                            <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] mb-2"></div>
                            <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] mb-2"></div>
                        </div>
                        <p className="text-center mt-2 text-[13px]">Standard</p>
                    </div>
                    {/* <div className="min-h-[126px] border border-[#BDBDBD] mr-3 p-2.5 rounded layout-design-container layout-design-item">
                        <div className="">
                            <div className="flex justify-between items-center mb-2">
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] w-[48%]"></div>
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] w-[48%]"></div>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] w-[48%]"></div>
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] w-[48%]"></div>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] w-[48%]"></div>
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] w-[48%]"></div>
                            </div>
                        </div>
                        <p className="text-center mt-2 text-[13px]">2 Columns</p>
                    </div> */}
                    {/* <div className="min-h-[126px] border border-[#BDBDBD] p-2.5 rounded layout-design-container layout-design-item">
                        <div className="flex justify-between">
                            <div className="w-[29%]">
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] mb-2"></div>
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[50px]"></div>
                            </div>
                            <div className="w-[29%]">
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[23px] mb-2"></div>
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[50px]"></div>
                            </div>
                            <div className="w-[29%]">
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[37px] mb-2"></div>
                                <div className="bg-[#F2F2F2] border border-[#BDBDBD] h-[37px]"></div>
                            </div>
                        </div>
                        <p className="text-center mt-2 text-[13px]">Masonry</p>
                    </div> */}
                </div>
            </div>
            <div className="p-6 flex justify-end">
                <PrimaryButton
                    position="prepend"
                    icon="/images/icons/save-btn.svg"
                    styling="bg-[#60579E] text-white rounded w-[149px] text-sm py-3 flex items-center justify-center"
                    content="Save"
                />
            </div>
        </form>
    )
}

export default CustomizationsForm