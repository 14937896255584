import React from "react";
import { useState } from "react";

import SettingLayout from "../../../components/settings/SettingsLayout";
import BillingLayout from "../../../components/settings/BillingLayout";
import PrimaryInput from "../../../components/common/inputs/PrimaryInput";
import PrimaryRadio from "../../../components/common/inputs/PrimaryRadio";
import PayoutMethodCard from "../../../components/settings/PayoutMethodCard";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import BalanceCard from "../../../components/settings/BalanceCard";
import AddPaymentCardPopup from "../../../components/settings//AddPaymentCardPopup";
import AddBalancePopup from "../../../components/settings/AddBalancePopup";


const BillingSetting = () => {

    const [addPayment, setAddPayment] = useState(false);
    const [isAddBalance, setAddBalance] = useState(false);

    const handleAddPayment = (e) => {

        if (e.target.id == 'change-password-container' || e.target.id == 'close-popup-icon') {
            setAddPayment(false);
        }
        else {
            setAddPayment(true);
        }
    }

    const handleAddBalance = (e) => {

        if (e.target.id == 'change-password-container' || e.target.id == 'close-popup-icon') {
            setAddBalance(false);
        }
        else {
            setAddBalance(true);
        }

    }

    const balance = [
        {
            amount: '200.16',
            title: 'SMS Balance'
        },
        {
            amount: '400.00',
            title: 'Available Balance'
        },
        {
            amount: '1,239',
            title: 'Available Messages'
        }

    ];

    return (
        <div>
            <SettingLayout>
                <BillingLayout>
                    <div className="p-6 flex flex-col lg:flex-row justify-between border-b border-[#BDBDBD]">
                        <div className="w-full lg:w-2/12">
                            <div className="font-medium txt-sm">Contact Email</div>
                            <p className="mt-3 text-sm">Where should invoices be sent?</p>
                        </div>
                        <div className="w-full lg:w-4/5">
                            <div className="flex items-start">
                                <div>
                                    <PrimaryRadio
                                        isChecked="true"
                                        name='account-mail'
                                        id='mail'
                                    />
                                </div>
                                <div className="ml-2">
                                    <div className="text-sm font-medium">Send To Account Email</div>
                                    <p className="text-[13px] text-[#828282] mt-1">joebiden@gmail.com</p>
                                </div>
                            </div>
                            <div className="flex items-start mt-6">
                                <div>
                                    <PrimaryRadio
                                        name='account-mail'
                                        id='mail'
                                    />
                                </div>
                                <div className="ml-2">
                                    <div className="text-sm font-medium">Send To An Alternative Email</div>
                                    <PrimaryInput
                                        type="text"
                                        placeholder='Enter email'
                                        width='w-full lg:w-[527px]'
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="p-6 flex flex-col lg:flex-row justify-between border-b border-[#BDBDBD]">
                        <div className="w-full lg:w-2/12">
                            <div className="font-medium txt-sm">Payment Method</div>
                            <p className="mt-3 text-sm">Select,add and edit your payment method</p>
                        </div>
                        <div className="w-full lg:w-4/5">
                            <div className="flex items-center justify-between flex-wrap">
                                <PayoutMethodCard image='/images/icons/payment-cards-icons/visa.svg' />
                                <PayoutMethodCard image='/images/icons/payment-cards-icons/american.svg' />
                                <PayoutMethodCard image='/images/icons/payment-cards-icons/discover.svg' />
                            </div>
                            <div className="mt-6">
                                <PrimaryButton
                                    type="button"
                                    handleClick={handleAddPayment}
                                    position='prepend'
                                    icon='/images/icons/black-rounded-plus.svg'
                                    content='Add Card'
                                    styling='flex justify-center items-center w-[175px] h-[39px] rounded bg-transparent border border-[#BDBDBD] hover:bg-[#F6F6F6]'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-6 flex flex-col lg:flex-row justify-between border-b border-[#BDBDBD]">
                        <div className="w-full lg:w-2/12">
                            <div className="font-medium txt-sm">Balance</div>
                            <p className="mt-3 text-sm">Add Balance or Check your remaining sms and available balance</p>
                        </div>
                        <div className="w-full lg:w-4/5">
                            <div className="flex items-center flex-wrap">
                                {
                                    balance.map((item, index) => {
                                        return <BalanceCard item={item} key={index} />
                                    })
                                }
                            </div>
                            <div className="mt-6">
                                <PrimaryButton
                                    type="button"
                                    handleClick={handleAddBalance}
                                    position='prepend'
                                    icon='/images/icons/black-rounded-plus.svg'
                                    content='Add Balance'
                                    styling='flex justify-center items-center w-[175px] h-[39px] rounded bg-transparent border border-[#BDBDBD] hover:bg-[#F6F6F6]'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-6 flex flex-col lg:flex-row justify-end items-center border-b border-[#BDBDBD]">
                        <div className="mb-3 lg:mb-0 lg:mr-3">
                            <PrimaryButton
                                position='prepend'
                                icon='/images/icons/delete.svg'
                                content='Discard'
                                styling='flex justify-center items-center  bg-transparent w-[183px] h-[40px] border border-[#BDBDBD] rounded text-sm hover:bg-[#F6F6F6]'
                            />
                        </div>
                        <div>
                            <PrimaryButton
                                position='prepend'
                                icon='/images/icons/save-btn.svg'
                                content='Save'
                                styling='flex justify-center items-center  bg-[#60579E] text-white w-[150px] h-[40px] border border-[#BDBDBD] rounded text-sm'
                            />
                        </div>
                    </div>
                </BillingLayout>
            </SettingLayout>
            {
                addPayment ? (
                    <AddPaymentCardPopup handleAddPayment={handleAddPayment} />
                ) : null
            }
            {
                isAddBalance ? (
                    <AddBalancePopup handleAddBalance={handleAddBalance} />
                ) : null
            }
        </div>
    )
}
export default BillingSetting;