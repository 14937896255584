import React from "react";
import {  useLocation } from "react-router-dom";
import { useState } from "react";

import Header from "../components/header/Header";
import PrimarySidebar from "../components/common/sidebar/PrimarySidebar";

const StoreLayout = ({ children  }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const location = useLocation();

    const handleShowSidebar = () => {
        setShowSidebar(!showSidebar);
    }
    
    return(
        <div>
            {
                location.pathname === "/login" ? (
                    <div className="main-body-container flex flex-col lg:flex-row  h-[100vh]">
                        <div className="w-full lg:w-[50%]">
                            {children}
                        </div>
                        <div className="hidden lg:block w-[50%]">
                            <img src='/images/login-bg.jpg' className="w-full h-full object-fill xl:object-cover" />
                        </div>
                    </div>
                ):(
                        <div className="main-body-container application bg-[#E5E5E5] flex">
                            <PrimarySidebar showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} />
                            <div className="w-full dash-content">
                                <Header handleExpand={handleShowSidebar} />
                                <div className="p-6 md:ml-[245px] min-h-[94vh]">
                                    {children}
                                </div>
                            </div>
                        </div>
                )
            }

        </div>
    )

}

export default StoreLayout;
