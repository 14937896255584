import React from 'react'

function PrimaryInput({
    label,
    width,
    type,
    placeholder,
    value,
    handleChange,
    name
}) {
    return (
        <div className="">
            {
                label && (
                    <label className="text-sm mb-1">{label}</label>
                )
            }
            <input type={type} name={name} placeholder={placeholder} value={value} onChange={handleChange} className={`rounded border border-[#BDBDBD] bg-transparent placeholder-[#828282] text-[13px] py-2.5 px-3.5 ${!label ? "mt-1" : ''}  outline-none  ${width}`} />

        </div>
    )
}

export default PrimaryInput;