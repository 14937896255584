import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import DashBoard from '../pages/dashboard';
import Products from '../pages/products';
import ReviewsRequest from '../pages/reviews-request';
import Customers from '../pages/customers';
import SingleQuestion from '../pages/questions/singleQuestion';
import Questions from '../pages/questions';
import Reviews from '../pages/reviews';
import SingleReview from '../pages/reviews/singleReview';
import MainWidgets from '../pages/widgets/main';
import Settings from '../pages/settings';
import Deliverability from "../pages/settings/deliverability";
import AppSetting from "../pages/settings/app";
import GoogleSettings from '../pages/settings/google';
import WebhookSetting from "../pages/settings/webhooks";
import AccountSetting from '../pages/settings/account';
import PlansSettings from '../pages/settings/plan';
import BillingSetting from '../pages/settings/billing';
import BillingHisory from '../pages/settings/billing/history';
import Login from '../pages/login';

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="dashboard" />} />
			<Route exact={true} path='/login' element={<Login />} />
			<Route exact={true} path='/dashboard' element={<DashBoard />} />
			<Route exact={true} path='/customers' element={<Customers />} />
			<Route exact={true} path='/products' element={<Products />} />
			<Route exact={true} path='/review-request' element={<ReviewsRequest />} />
			<Route exact={true} path='/questions' element={<Questions />} />
			<Route exact={true} path='/questions/:questionId' element={<SingleQuestion />} />
			<Route exact={true} path='/reviews' element={<Reviews />} />
			<Route exact={true} path='/reviews/:reviewId' element={<SingleReview />} />
			<Route exact={true} path='/widgets/main' element={<MainWidgets />} />
			<Route exact={true} path='/settings' element={<Settings />} />
			<Route exact={true} path="/settings/deliverability" element={<Deliverability />} />
			<Route exact={true} path="settings/apps" element={<AppSetting />} />
			<Route exact={true} path="/settings/google" element={<GoogleSettings />} />
			<Route exact={true} path="/settings/webhooks" element={<WebhookSetting />} />
			<Route exact={true} path="/settings/account" element={<AccountSetting />} />
			<Route exact={true} path="/settings/plans" element={<PlansSettings />} />
			<Route exact={true} path="/settings/billing" element={<BillingSetting />} />
			<Route exact={true} path="/settings/billing/history" element={<BillingHisory />} />
		</Routes>
	)
}

export default AppRoutes;