import React from "react";
import { useState } from "react";
import moment from "moment";
import Rating from "react-rating";

import ActionNav from "../common/navbar/ActionNav";
import ProductRatingCard from "../product/ProductRatingCard";
import { alphabetColors } from "../../utils/string";
import ImagePreview from "../common/popup/ImagePreview";

function SingleReviewComponent({
	heading,
	data,
	handleApprove,
	handleSubmit,
	isEdit,
	handleEdit,
	handleArchive,
}) {
	const [rating, setRating] = useState(4);
	const [description, setDescription] = useState("description");
	const [showImagePreview, setShowImagePreview] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleDiscard = () => {
		handleEdit();
	};

	const handleReviewRating = (rate) => {
		setRating(rate);
	};
	const formattedDate = moment(
		data?.created_at,
		"YYYY-MM-DD HH:mm:ss"
	).format("MMMM DD, YYYY hh:mm A");

	const handleClosePreview = () =>  {
		setShowImagePreview(false);
		setSelectedIndex(0);
	};

	return (
		<div className="single-question-container">
			<div className="bg-white mt-4 rounded body-shadow">
				<div className="border-b border-[#BDBDBD]">
					<div className="mt-6">
						<ActionNav
							title={heading}
							archiveStatus={2}
							publishedStatus={1}
							status={data?.status}
							handleArchive={() => handleArchive(2)}
							handleApprove={() => handleApprove(1)}
							date={formattedDate}
							path={"review"}
						/>
					</div>
				</div>
				<div className="p-6 flex justify-between flex-col lg:flex-row ">
					<div className="flex w-[90%]">
						<div
							className="h-[43px] w-[43px] min-w-[43px] flex items-center justify-center rounded-full mix-blend-difference font-semibold"
							style={{
								background:
									alphabetColors?.[
										data?.customer?.first_name?.[0]?.toLowerCase?.()
									],
							}}
						>
							{data?.customer?.first_name?.[0]?.toUpperCase?.()}
						</div>
						<div className="ml-2.5">
							<div>
								<h3 className="text-[15px] font-medium">
									<span className="capitalize">
										{data?.customer?.first_name}
									</span>{" "}
									<span className="capitalize">
										{data?.customer?.last_name}
									</span>
								</h3>
								<p className="text-sm">
									<span className="text-[#828282]">
										{data?.customer?.email}
									</span>
								</p>
							</div>

							{data?.rating && (
								<div className="mt-1">
									<div
										className={`rating-stars-innerwrapper flex items-center `}
									>
										<Rating
											initialRating={data?.rating}
											readonly={!isEdit}
											onClick={handleReviewRating}
											emptySymbol={
												<div className="w-7 h-7 mr-1">
													<svg
														width="26"
														height="26"
														viewBox="0 0 26 26"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M12.2916 1.03804C12.5251 0.366371 13.4749 0.366371 13.7084 1.03804L16.3554 8.65293C16.4584 8.94924 16.7349 9.15014 17.0485 9.15653L25.1086 9.32078C25.8196 9.33527 26.1131 10.2387 25.5465 10.6683L19.1222 15.5388C18.8723 15.7283 18.7666 16.0534 18.8575 16.3536L21.192 24.07C21.3979 24.7507 20.6294 25.309 20.0457 24.9028L13.4284 20.2981C13.1709 20.1189 12.8291 20.1189 12.5716 20.2981L5.95428 24.9028C5.3706 25.309 4.60211 24.7507 4.80803 24.07L7.14253 16.3536C7.23337 16.0534 7.12775 15.7283 6.87778 15.5388L0.45353 10.6683C-0.113119 10.2387 0.180415 9.33527 0.891358 9.32078L8.9515 9.15653C9.26513 9.15014 9.54165 8.94924 9.64464 8.65293L12.2916 1.03804Z"
															fill="#BDBDBD"
														/>
													</svg>
												</div>
											}
											fullSymbol={
												<div className="w-7 h-7 mr-1">
													<svg
														width="26"
														height="26"
														viewBox="0 0 26 26"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M12.2916 1.03804C12.5251 0.366371 13.4749 0.366371 13.7084 1.03804L16.3554 8.65293C16.4584 8.94924 16.7349 9.15014 17.0485 9.15653L25.1086 9.32078C25.8196 9.33527 26.1131 10.2387 25.5465 10.6683L19.1222 15.5388C18.8723 15.7283 18.7666 16.0534 18.8575 16.3536L21.192 24.07C21.3979 24.7507 20.6294 25.309 20.0457 24.9028L13.4284 20.2981C13.1709 20.1189 12.8291 20.1189 12.5716 20.2981L5.95428 24.9028C5.3706 25.309 4.60211 24.7507 4.80803 24.07L7.14253 16.3536C7.23337 16.0534 7.12775 15.7283 6.87778 15.5388L0.45353 10.6683C-0.113119 10.2387 0.180415 9.33527 0.891358 9.32078L8.9515 9.15653C9.26513 9.15014 9.54165 8.94924 9.64464 8.65293L12.2916 1.03804Z"
															fill="#F2C94C"
														/>
													</svg>
												</div>
											}
										/>
									</div>
								</div>
							)}
							<div
								className="text-[15px]"
								dangerouslySetInnerHTML={{
									__html: data?.review,
								}}
							/>
							<div className="flex gap-2 mt-1 flex-wrap">
								{data?.images?.map((image, index) => (
									<img
										key={index}
										src={image}
										className=" w-36 h-36 rounded object-cover"
										onClick={()=> {
											setShowImagePreview(true);
											setSelectedIndex(index);

										}}
									/>
								))}
							</div>
							<div>
								{isEdit ? (
									<div className="mt-2">
										<input
											type="text"
											className="border border-[#BDBDBD] rounded p-3.5"
											value={"description"}
											onChange={(e) =>
												setDescription(e.target.value)
											}
										/>
										<div className="flex mt-2">
											<button
												type="reset"
												value="Reset"
												className="px-4 rounded py-1 text-[#60579E] border border-[#60579E] flex items-center justify-center md:mr-6"
												onClick={handleDiscard}
											>
												Discard
											</button>
											<button
												className="px-4 rounded py-1 bg-[#60579E] text-white flex items-center justify-center"
												onClick={() =>
													handleSubmit(
														description,
														rating && rating
													)
												}
											>
												save
											</button>
										</div>
									</div>
								) : (
									<p className="text-[#4F4F4F] text-[15px]">
										{data?.review?.review}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="max-w-[380px] mt-4 lg:mt-0">
						{
							data?.order_id ? (
								<p className="text-right font-medium mb-2 text-[#007185]">Order#{data?.order_id}</p>
							) : null
						}
						<ProductRatingCard data={data} />
					</div>
				</div>
			</div>
			{
				showImagePreview && <ImagePreview selectedIndex={selectedIndex} images={data?.images} onClose={handleClosePreview} />
			}
		</div>
	);
}

export default SingleReviewComponent;
