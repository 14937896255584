import React, { useMemo, useRef, useState } from "react";
import JoditEditor, {Jodit} from "jodit-react";

const isNotEnglishLetter = (char) =>
	/[^\w\s._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|’”“‘?– "’‘“”°é¡™£¢∞§¶•ªº≠«‘“æ…≥≤÷œåΩ∑ß≈ƒ©†∫¥˙˜¨∆µ˚€‹›ﬁﬂ‡°·‚±»ÆÚ¿˘¯Â◊Ç˛¸ÅÏ˝ÓÍÎ˝ÔÒ∏Øˆ¨Áˇ‰´„Œсмхπøç×\u00a9|\u00ae|\u2000-\u3300|\ud83c\ud000-\udfff|\ud83d\ud000-\udfff|\ud83e\ud000-\udfff✔️-]/g.test(
		char
	);

function preparePaste(jodit) {
	jodit.e.on(
		"change",
		(e) => {
			if (isNotEnglishLetter(Jodit.modules.Helpers.stripTags(e))) {
				jodit.setEditorValue(
					e.replace(
						/[^\w\s._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|’”“‘?– "’‘“”°é¡™£¢∞§¶•ªº≠«‘“æ…≥≤÷œåΩ∑ß≈ƒ©†∫¥˙˜¨∆µ˚€‹›ﬁﬂ‡°·‚±»ÆÚ¿˘¯Â◊Ç˛¸ÅÏ˝ÓÍÎ˝ÔÒ∏Øˆ¨Áˇ‰´„Œсмхπøç×\u00a9|\u00ae|\u2000-\u3300|\ud83c\ud000-\udfff|\ud83d\ud000-\udfff|\ud83e\ud000-\udfff✔️-]/g,
						""
					)
				);
			}
		},
		{ top: true }
	);
}

Jodit.plugins.add("prepare", preparePaste);

const RichTextEditor = ({ value, handleChange }) => {
	const editor = useRef(null);
	const [description] = useState(value);

	const config = useMemo(
		() => ({
			askBeforePasteHTML: false,
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			placeholder: "Add Product Description",
			height: 400,
			limitChars: 3000,
			language: "en",
			statusbar: false,
			controls: {
				ul: {
					list: undefined,
				},
				ol: {
					list: undefined,
				},
			},
			disablePlugins:
				"about,add-new-line,ai-assistant,color,copyformat,delete-command,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,image,image-processor,image-properties,indent,inline-popup,key-arrow-outside,line-height,media,link,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,redo-undo,resize-handler,resizer,search,select,select-cells,source,speech-recognize,spellcheck,stat,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath",
			buttons: "bold,underline,italic,strikethrough, left, center, right",
		}),
		[]
	);

	return (
		<JoditEditor
			ref={editor}
			value={description || ""}
			config={config}
			tabIndex={1} // tabIndex of textarea
			// onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={(newContent) => {
				handleChange(newContent); // Pass newContent directly
			}}
		/>
	);
};

export default RichTextEditor;
