import React from "react";
import PrimaryRadio from "../common/inputs/PrimaryRadio";

const PayoutMethodCard = ({ image }) => {
    return (
        <div className="border border-[#BDBDBD] rounded p-6 payment-method-card mb-3 lg:mb-0 mr-0 lg:mr-3 ">
            <div className="flex justify-between items-center h-[57px]">
                <div><img src={image} alt="" /></div>
                <div><img src="/images/icons/delete.svg" alt="" /></div>
            </div>
            <div className="mt-8 flex justify-between">
                <div className="font-medium flex items-center">
                    <div className="mr-[10px] 2xl:mr-[20px] h-4">****</div>
                    <div className="mr-[10px]  2xl:mr-[20px] h-4">****</div>
                    <div className="mr-[10px]  2xl:mr-[20px] h-4">****</div>
                    <div className="mr-[10px] 2xl:mr-0 font-normal text-base">1836</div>
                </div>
                <div>
                    <PrimaryRadio
                        name='account-mail'
                        id='mail'
                    />
                </div>
            </div>
            <div className="mt-[22px] flex items-center">
                <div className="text-sm text-[#828282] mr-[10px]">VALID THRU</div>
                <div className="text-sm ">11/21</div>

            </div>
        </div>
    )
}
export default PayoutMethodCard;