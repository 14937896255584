import React from "react";
import PrimaryButton from "../../common/buttons/PrimaryButton";


function CssEditorForm() {
    return (
        <div>
            <form>
                <div className="border-b border-[#BDBDBD]">
                    <div className="p-6">
                        <div className="text-base font-medium">
                            Custom CSS Code
                        </div>
                        <div className="mt-3">
                            <img src="/images/Editor.svg" className="w-full" />
                        </div>

                    </div>
                </div>
                <div className="p-6 flex justify-end items-center">
                    <PrimaryButton
                        position="prepend"
                        icon="/images/icons/save-btn.svg"
                        content="Save"
                        styling="bg-[#60579E] text-white rounded w-[149px] text-sm py-3 flex items-center justify-center"
                    />
                </div>
            </form>
        </div>
    )
}

export default CssEditorForm