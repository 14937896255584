import React from "react";
import { Link,useLocation } from "react-router-dom";

const SettingNav = () => {

    const location = useLocation();

    return (
        <ul className="flex items-center overflow-auto setting-nav-container">
            <li className="mr-3">
                <p className="text-sm mb-1 font-medium text-[#828282] text-center">
                    Settings
                </p>
                <div className="flex border border-[#BDBDBD] rounded overflow-hidden">
                    <Link to='/settings'>
                        <a className={`w-[81px] h-[39px]  flex justify-center items-center hover:bg-[#F6F6F6]  text-sm  ${location.pathname == '/settings' ? ' bg-[#F6F6F6]' : "bg-white"}`} to="/setting">
                            General
                        </a>
                    </Link>
                    <Link to='/settings/deliverability'>
                        <a className={`w-[115px] h-[39px] flex justify-center items-center hover:bg-[#F6F6F6]   border-l border-[#BDBDBD] text-sm ${location.pathname == '/settings/deliverability' ? 'bg-[#F6F6F6]' : "bg-white"}`} to="/setting-deliverability">
                            Deliverability
                        </a>
                    </Link>
                </div>
            </li>
            <li className="mr-3">
                <p className="text-sm mb-1 font-medium text-[#828282] text-center">
                    Integrations
                </p>
                <div className="flex border border-[#BDBDBD] rounded overflow-hidden">
                    <Link to='/settings/apps'>
                        <a className={`w-[63px] h-[39px]  flex justify-center items-center hover:bg-[#F6F6F6]  text-sm ${location.pathname == ('/settings/apps') ? ' bg-[#F6F6F6]' : 'bg-white'}`} to="/apps-setting">
                            Apps
                        </a>
                    </Link>
                    <Link to="/settings/google">
                        <a className={`w-[81px] h-[39px] flex justify-center items-center hover:bg-[#F6F6F6]   border-l border-[#BDBDBD] text-sm ${location.pathname == ('/settings/google') ? ' bg-[#F6F6F6] ' : "bg-white"}`} to="/google-setting">
                            Google
                        </a>
                    </Link>
                    <Link to="/settings/webhooks">
                        <a className={`w-[81px] h-[39px] flex justify-center items-center  hover:bg-[#F6F6F6]   border-l border-[#BDBDBD] text-sm ${location.pathname == ('/settings/webhooks') ? "bg-[#F6F6F6]" : 'bg-white'}`} to="/webhooks-setting">
                            Webhooks
                        </a>
                    </Link>
                </div>
            </li>
            <li className="mr-3">
                <p className="text-sm mb-1 font-medium text-[#828282] text-center">
                    Accounts
                </p>
                <div className="flex border border-[#BDBDBD] rounded overflow-hidden">
                    <Link to='/settings/account'>
                        <a className={`w-[84px] h-[39px]  flex justify-center items-center text-sm hover:bg-[#F6F6F6] ${location.pathname == ('/settings/account') ? "bg-[#F6F6F6]" : 'bg-white'}`} to="/account-setting">
                            Account
                        </a>
                    </Link>
                    <Link to="/settings/plans">
                        <a className={`w-[70px] h-[39px] flex justify-center items-center  border-l hover:bg-[#F6F6F6]  border-[#BDBDBD] text-sm ${location.pathname == ('/settings/plans') ? "bg-[#F6F6F6]" : ' bg-white'}`} to="/plans-setting">
                            Plans
                        </a>
                    </Link>

                    <Link to="/settings/billing">
                        <a className={`w-[71px] h-[39px] flex justify-center items-center  border-l hover:bg-[#F6F6F6]  border-[#BDBDBD] text-sm ${location.pathname == ('/settings/billing') ? ' bg-[#F6F6F6] ' : 'bg-white'}`} to="/billing-setting">
                            Billing
                        </a>
                    </Link>
                </div>
            </li>
        </ul>
    )
}
export default SettingNav;