import React from "react";
const BalanceCard = ({ item }) => {
    const { title, amount } = item;
    return (
        <div className="p-6 rounded border border-[#BDBDBD] w-full lg:w-[180px] mb-3 xl:mb-0 lg:mr-6">
            <div className="text-[21px] font-medium">
                {amount}
            </div>
            <div className="mt-[10px] text-[#828282] text-sm">
                {title}
            </div>
        </div>
    )
}
export default BalanceCard;