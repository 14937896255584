import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import PrimaryHeading from "../../components/common/headings/PrimaryHeading";
import PrimaryTable from "../../components/common/table/PrimaryTable";
import PrimarySearch from "../../components/common/inputs/PrimarySearch";
import NavLinks from "../../components/common/navbar/PrimaryNavbar";
import SpinnerLoader from "../../components/common/loader/SpinnerLoader";
import ProductInfoCard from "../../components/product/ProductInfoCard";
import CustomerInfo from "../../components/customer/CustomerInfo";
import ReviewCard from "../../components/reviews/ReviewCard";
import { disputeReview, getReviews } from "../../services/ReviewsServices";
import { useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../components/common/pagination";
import { capitalizeString } from "../../utils/string";
import DatePicker, { DateObject } from "react-multi-date-picker";
import moment from "moment";
import PrimaryPopupWrapper from "../../components/common/popup/PrimaryPopup";
import RatingFilterDropdown from "../../components/reviews/RatingFilterDropdown";
import FilterTag from "../../components/common/inputs/FilterTag";
let timeout = null;
const Reviews = () => {
	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(true);
	const history = useNavigate();
	let { search, pathname } = useLocation();
	const [search_value, setSearchValue] = useState("");
	const [current_page, setCurrentPage] = useState(1);
	const user = useSelector((state) => state.auth.user);
	const params = new URLSearchParams(window?.location?.search);
	const calRef = useRef();

	const [value, setValue] = useState([]);
	const [openPopup, setOpenPopup] = useState(false);
	const [disputeError, setdisputeError] = useState("");
	const [selectedRating, setRating] = useState({});
	const [disputeDetail, setDisputeDetail] = useState({
		reason: "",
		order_id: null,
		product_id: null,
		type: "review_dispute",
		product_review_id: null,
	});

	useEffect(() => {
		let status = params.get("type");
		switch (status) {
			case "unpublished":
				status = 0;
				break;
			case "published":
				status = 1;
				break;
			case "archived":
				status = 2;
				break;

			default:
				status = 0;
				break;
		}
		let searchParam = params?.get("q") || undefined;
		let pageParam = params.get("page") || undefined;
		let ratingParam = params?.get("rating") || undefined;
		let startDateParam = params?.get("start") || undefined;
		let endDateParam = params.get("end") || undefined;
		setSearchValue(searchParam || "");
		setCurrentPage(pageParam || 1);
		setRating(ratingParam || {});
		if(startDateParam){
			setValue([
				new DateObject(startDateParam || new Date()),
				new DateObject(endDateParam || startDateParam),
			]);
		}
		else {
			setValue([]);
		}
		getProductsReviews(
			status,
			pageParam,
			searchParam,
			ratingParam,
			startDateParam,
			endDateParam
		);
	}, [search]);

	function getProductsReviews(status, page, search, rating, start, end) {
		getReviews(status, page, search, rating, start, end)
			.then((res) => setReviews(res.data))
			.catch((err) => {
				console.log(err);
			})
			.finally((res) => setLoading(false));
	}
	const navLinks =
		user?.store_ids[0] <= 9 || user?.is_admin
			? [
					{
						name: "Published",
						link: "published",
					},
					{
						name: "Unpublished",
						link: "unpublished",
					},
					{
						name: "Archived",
						link: "archived",
					},
			  ]
			: [
					{
						name: "Published",
						link: "published",
					},
			  ];

	const columns = [
		{
			name: "Product",
			selector: (row) => row.product,
			width: "480px",
		},
		{
			name: "Customer",
			selector: (row) => row.customer,
			// width: "300px",
		},
		{
			name: "Review",
			selector: (row) => row.reviews,
			// width: "300px",
		},
		{
			name: "Date",
			selector: (row) => row.date,
			// width: "300px",
		},
		{
			name: "Status",
			selector: (row) => row.status,
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const handleClick = (id) => {
		let type = new URLSearchParams(search).get("type");

		history(`/reviews/${id}?type=${type}`);
	};

	const handleChange = (e) => {
		const { value } = e.target;
		setSearchValue(value);

		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			if (value.trim().length > 0) {
				params.set("q", value?.trim());
			} else {
				params.delete("q");
			}

			params.delete("page");
			history(pathname + "?" + params);
		}, 1000);
	};

	const handleDispute = (e, order_id, product_id, product_review_id) => {
		handlePopup({ order_id, product_id, product_review_id });
	};

	const data = reviews?.data?.map((item, index) => {
		const {
			customer_name,
			customer_email,
			rating,
			review,
			status,
			product,
			id,
			created_at,
		} = item;

		const formattedDate = moment(
			created_at,
			"YYYY-MM-DD hh:mm:ss A"
		).format("DD-MM-YYYY");
		return {
			product: (
				<ProductInfoCard
					cursor="cursor-pointer"
					handleClick={() => handleClick(id)}
					id={id}
					img={product?.image}
					title={product?.title}
				/>
			),
			customer: (
				<CustomerInfo
					cursor="cursor-pointer"
					handleClick={() => handleClick(id)}
					name={customer_name ? capitalizeString(customer_name) : ""}
					email={customer_email ? customer_email : ""}
				/>
			),
			reviews: (
				<ReviewCard
					handleClick={() => handleClick(id)}
					rating={rating ? rating : ""}
					description={review ? review : ""}
					// time={created_at}
					cursor="cursor-pointer"
				/>
			),
			date: formattedDate,
			status: (
				<div
					className={`rounded cursor-pointer ${
						item.status.toLowerCase() == "unpublished" ||
						item.status?.toLowerCase() == "archived"
							? "bg-[#F3EFDA] text-[#9A7800]"
							: "bg-[#E4F5EB] text-[#00933D]"
					}  text-[13px] p-1  ml-1`}
					onClick={() => handleClick(id)}
				>
					{status ? status : ""}
				</div>
			),
			action: (
				<button
					disabled={
						!item?.product?._id ||
						!item?.order?._id ||
						item?.dispute === 1
							? true
							: false
					}
					className=" disabled:opacity-50 text-[13px]  border border-[#BDBDBD] leading-[11px] hover:bg-[##f2f2f2] px-[10px] py-[7.5px] rounded-[3px] cursor-pointer"
					onClick={(e) => {
						const { order, product } = item;
						handleDispute(e, order?._id, product?._id, item?.id);
					}}
				>
					Dispute
				</button>
			),
		};
	});

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		params.set("page", page);
		history(pathname + "?" + params);
	};

	const handleDateChange = (values) => {
		setValue(values);
	};

	const clearDateFilter = () => {
		params.delete('start');
		if(params.get('end')){
			params.delete('end');
		}
		history(pathname + "?" + params);
	};

	const submitDispute = (e) => {
		e.preventDefault();
		const { value: textAreaValue } = e.target.elements["dispute_reason"];
		const submitButton = e.target.elements["reason_button"];
		if (!textAreaValue) {
			setdisputeError("Reason cannot be empty!");
			return true;
		}

		if (submitButton?.disabled) {
			return true;
		}

		if (!submitButton?.disabled) {
			submitButton.setAttribute("disabled", true);
			disputeReview(disputeDetail)
				.then((res) => {
					let status = params.get("type");
					switch (status) {
						case "unpublished":
							status = 0;
							break;
						case "published":
							status = 1;
							break;
						case "archived":
							status = 2;
							break;
			
						default:
							status = 0;
							break;
					}
					handlePopup();
					getProductsReviews(status, current_page, search_value, selectedRating, value?.[0]?.format?.(), value?.[1]?.format?.());
					submitButton.removeAttribute("disabled", false);
				})
				.catch((err) => {
					submitButton.removeAttribute("disabled", false);
					setdisputeError(
						err?.response?.data?.message ||
							JSON.stringify(err?.response)
					);
				});
		}
	};

	const handlePopup = (params) => {
		try {
			const disputeObj = { ...disputeDetail };
			
			if (params?.order_id) {
				disputeObj["order_id"] = params?.order_id;
				disputeObj["product_id"] = params?.product_id;
				disputeObj["product_review_id"] = params?.product_review_id;
			} else {
				disputeObj["order_id"] = null;
				disputeObj["product_id"] = null;
				disputeObj["reason"] = "";
				disputeObj["product_review_id"] = null;
			}
			setDisputeDetail(disputeObj);
			setOpenPopup(!openPopup);
		} catch (error) {
			console.log(error);
		}
	};

	const onDisputeReasonChange = (e) => {
		const { value } = e.target;
		const trimedValue = value.trim();

		const disputeObj = { ...disputeDetail };

		if (trimedValue) {
			if (/[A-Za-z0-9., ]+/.test(trimedValue)) {
				disputeObj["reason"] = value;
				setDisputeDetail(disputeObj);
			} else {
				setdisputeError(
					"Reason can only container Alpha Numeric characters"
				);
			}
		} else {
			disputeObj["reason"] = "";
			setDisputeDetail(disputeObj);
			setdisputeError("");
		}
	};

	const handleRatingFilter = (rating) => {
		params?.set("rating", rating?.value);
		params?.delete("page");
		history(pathname + "?" + params);
	};

	const handleSubmitFilter = () => {
		params.delete("page");
		if (value?.[0]) {
			params.set("start", value?.[0]?.format?.());
		}
		if (value?.[1]) {
			params.set("end", value?.[1]?.format?.());
		}
		calRef.current.closeCalendar(false);
		history(pathname + "?" + params);
	};

	const handleClose = () => {
		let startDateParam = params?.get("start") || undefined;
		let endDateParam = params.get("end") || undefined;
		setValue([
			new DateObject(startDateParam || new Date()),
			new DateObject(endDateParam || startDateParam),
		]);
		calRef.current.closeCalendar(false);
	};


	return (
		<div>
			<PrimaryHeading heading="Reviews" />
			{openPopup ? (
				<PrimaryPopupWrapper
					title={"Review Dispute"}
					handleClosePopup={handlePopup}
				>
					<form onSubmit={submitDispute} className="">
						<div className="p-[25px] border-b border-[#BDBDBD] ">
							<label
								htmlFor="dispute-reason"
								className="block mb-1 text-sm text-[#232323]"
							>
								Reason
							</label>
							<textarea
								className="block h-36 w-full resize-none border border-[#BDBDBD] rounded-[3px] py-2.5 px-4"
								placeholder="Enter dispute reason here"
								id="dispute-reason"
								onChange={onDisputeReasonChange}
								value={disputeDetail["reason"]}
								name="dispute_reason"
							></textarea>

							<small className="text-red-500">
								{disputeError}
							</small>
						</div>
						<div className="p-[25px] flex justify-end gap-2">
							<button
								onClick={handlePopup}
								className=" border border-[#BDBDBD] hover:bg-[#f6921e] hover:text-[#fff] hover:border-[#f6921e] px-[25px] py-2.5 rounded-[3px] font-medium cursor-pointer"
							>
								Cancel
							</button>
							<button
								name="reason_button"
								className="bg-[#3b3561] disabled:opacity-60 text-white border border-[#3b3561] hover:bg-[#f6921e] hover:text-[#fff] hover:border-[#f6921e] px-[25px] py-2.5 rounded-[3px] font-medium cursor-pointer"
							>
								Submit
							</button>
						</div>
					</form>
				</PrimaryPopupWrapper>
			) : null}
			<div className="bg-white mt-4 rounded body-shadow">
				<NavLinks links={navLinks} />
				<div className="p-6">
					<div className="flex flex-col xl:flex-row justify-between xl:items-center">
						<div className="w-full xl:w-auto flex-grow mb-3 lg:mb-0">
							<PrimarySearch
								placeholder="Search Reviews"
								handleChange={handleChange}
								value={search_value}
								className={"px-3 py-2"}
							/>
						</div>
						<div className="flex flex-col sm:flex-row justify-end mt-4 xl:mt-0 ml-0 xl:ml-6">
							<div className="relative mb-4 sm:mb-0 sm:mr-4">
								<DatePicker
									range
									value={value}
									onChange={handleDateChange}
									containerClassName="reviews-custom-container"
									onClose={() => {
										let startDateParam =
											params?.get("start") || undefined;
										let endDateParam =
											params.get("end") || undefined;
										setValue([
											new DateObject(
												startDateParam || new Date()
											),
											new DateObject(
												endDateParam || startDateParam
											),
										]);
									}}
									ref={calRef}
									render={
										<button
											className="border border-[#BDBDBD] leading-[18.72px] rounded flex items-center py-2 px-6 text-[#232323] text-[14.4px]"
										>
											<img
												src="/images/icons/calender-icon.svg"
												height={18}
												width={16}
												className="mr-1.5 w-[16px] h-[18px]"
												alt="calender icon"
											/>
											Date
										</button>
									}
									maxDate={new DateObject()}
								>
									<div className="">
										<button
											className="rmdp-button rmdp-action-button"
											onClick={handleSubmitFilter}
										>
											Submit
										</button>
										<button
											className="rmdp-button rmdp-action-button"
											onClick={handleClose}
										>
											Close
										</button>
									</div>
								</DatePicker>
							</div>
							<RatingFilterDropdown
								handleRatingFilter={handleRatingFilter}
								selectedRating={selectedRating}
							/>
						</div>
					</div>
					<div className="flex items-center mt-3">
						{selectedRating.length > 0 && (
							<div className={`${value?.length > 0 && "mr-4"}`}>
								<FilterTag
									handleClear={() => {
										params.delete("rating");
										history(pathname + "?" + params);
									}}
									name={`${selectedRating} Star Rating`}
								/>
							</div>
						)}
						{params?.get('start') && (
							<FilterTag
								handleClear={clearDateFilter}
								name={`${moment(params.get('start')).format("DD MMM, YYYY")} - ${
									params.get('end')
										? moment(params.get('end')).format("DD MMM, YYYY")
										: moment(params.get('start')).format("DD MMM, YYYY")
								}`}
							/>
						)}
					</div>

					<div className="mt-6">
						{loading ? (
							<SpinnerLoader />
						) : (
							<PrimaryTable
								columns={columns}
								data={data}
								// selectable
								rowUrl={`/reviews`}
								pointerOnHover
							/>
						)}
					</div>
					{reviews?.last_page && reviews?.last_page > 1 ? (
						<Pagination
							pageCount={reviews?.last_page}
							handlePageClick={handlePageClick}
							current_page={current_page}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Reviews;
