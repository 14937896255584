import React from "react";
import SettingLayout from "../../components/settings/SettingsLayout";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";

const GoogleSettings = () => {
    return (
        <div>
            <SettingLayout>
                <form>
                    <div className="border-b border-[#BDBDBD] flex flex-col lg:flex-row  p-6">
                        <div className="w-full lg:w-[40%] mr-9">
                            <div className="text-base font-medium">Product Ratings</div>
                            <p className="mt-3 text-sm">
                                The Product Ratings program allows you to display aggregated reviews for your products to customers shopping on Google. Product ratings are shown in ads and free product listings, appearing as 1 to 5-star ratings that also display the total number of reviews for the product.
                            </p>
                            <p className="mt-3 text-sm">
                                *To participate in Product Ratings by uploading a feed of reviews, you must have a minimum of 50 reviews in your Product Ratings feed.
                            </p>
                        </div>
                        <div>
                            <div className="text-sm font-normal">Product Ratings</div>
                            <div className="mt-3">
                                <PrimaryButton
                                    styling='bg-[#60579E] text-white rounded w-[135px] h-[40px] flex justify-center items-center'
                                    content='Enable'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row p-6 border-b border-[#BDBDBD]">
                        <div className="w-full lg:w-[40%] mr-9">
                            <div className="text-base font-medium">Seller Ratings</div>
                            <p className="mt-3 text-sm">
                                Seller ratings are an automated extension type that showcase advertisers with high ratings.
                            </p>
                            <p className="mt-3 text-sm">
                                Seller ratings, which appear below text ads, help people find businesses that offer quality services. Seller ratings can help advertisers improve ad performance and earn more qualified leads.
                            </p>

                        </div>
                        <div>
                            <div className="text-sm font-normal">Seller Ratings</div>
                            <div className="mt-3">
                                <PrimaryButton
                                    styling='bg-transparent border border-[#BDBDBD] text-black rounded w-[135px] h-[40px] flex justify-center items-center hover:bg-[#F6F6F6]'
                                    content='Disable'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-center p-6">
                        <PrimaryButton
                            styling='bg-transparent mr-2.5 border border-[#BDBDBD] text-black rounded w-[135px] h-[40px] flex justify-center items-center hover:bg-[#F6F6F6]'
                            content='Discard'
                            position="prepend"
                            icon="/images/icons/delete.svg"
                        />
                        <PrimaryButton
                            styling='bg-[#60579E] text-white  border border-[#BDBDBD]  rounded w-[135px] h-[40px] flex justify-center items-center '
                            content='Save'
                            position="prepend"
                            icon="/images/icons/save-btn.svg"
                        />
                    </div>
                </form>
            </SettingLayout>
        </div>
    )
}
export default GoogleSettings;