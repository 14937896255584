import React, { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";

import PrimaryHeading from "../components/common/headings/PrimaryHeading";
import DashboardCard from "../components/dashboard/DashboardCard";
import PrimaryAccordion from "../components/common/accordion/PrimaryAccordion";
import { getDashboardData } from "../services/ReviewsServices";
import { useSelector } from "react-redux";
import SpinnerLoader from "../components/common/loader/SpinnerLoader";
// Notification Section For Top Products and Recent Activity (Warning: Do not remove)
// import NotificationSection from "../components/dashboard/NotificationSection";

const DashBoard = () => {
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState([new DateObject(), new DateObject()]);

	const user = useSelector((state) => state.auth.user);

	const [dashboardData, setDashboard] = useState([
		{
			title: "New Reviews",
			count: 0,
			icon: "/images/icons/review-card.svg",
			key: "reviews",
		},

		{
			title: "New Questions Asked",
			count: 0,
			icon: "/images/icons/question-round.svg",
			key: "question",
			unanswered:0,
		
		},
	]);

	useEffect(() => {
		if (user) {
			setLoading(true);
			getDashboardData({
				start_date: value?.[0]?.format?.(),
				end_date: value?.[1]?.format?.(),
				store_ids: user?.store_ids,
				isAdmin: user?.is_admin,
			})
				.then((res) => {
					const dashboardArr = [...dashboardData];
					dashboardArr[0].count = res.data.reviews;
					dashboardArr[1].count = res.data.questions;
					dashboardArr[1].unanswered  = res?.data?.total_unanswered_questions;
					setDashboard(dashboardArr);
					setTimeout(() => {
						setLoading(false);
					}, 1000);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			setLoading(false);
		}
	}, [value, user]);

	const handleDateChange = (values) => {
		setValue(values);
	};


	return (
		<>
			{loading ? (
				<div className="fixed top-0 left-0 w-full h-full z-50  backdrop-blur-sm ">
					<SpinnerLoader />
				</div>
			) : null}

			<div className="">
				<div className=" flex flex-col md:flex-row justify-between md:items-center ">
					<PrimaryHeading heading="Dashboard" />
					<div className="relative">
						<DatePicker
							range
							value={value}
							onChange={handleDateChange}
							inputClass="h-10 px-2 rounded w-56"
							maxDate={new DateObject()}
							className="rmdp-mobile"
							mobileLabels={{
								OK: "Submit",
								CANCEL: "Close",
							}}
						/>
					</div>
				</div>

				<div className="flex flex-col lg:flex-row  dashborad-cards-container overflow-auto mt-6">
					{dashboardData.map((item, index) => {
						return <DashboardCard  item={item} key={index} />;
					})}
				</div>
				<div className="flex flex-col lg:flex-row justify-between mt-6 gap-4 ">
					<div className="w-full lg:w-[50%] xl:w-7/12 flex-1">
						{/* ----- Reviews to Moderate Start (Warning: Do not Remove) ----- */}

						{/* <div className="mb-6 bg-white">
                        <PrimaryAccordion
                            title="41 reviews to moderate"
                        />
                    </div> */}

						{/* ----- Reviews to Moderate ENd (Warning: Do not Remove) ----- */}

						{/* <div className="mb-6 bg-white">
						<PrimaryAccordion title="1 question to answer" />
					</div> */}
					</div>

					{/* ----- Top Selling Product Start (Warning: Do not Remove) ----- */}

					{/* <div className="w-full lg:w-[45%] xl:w-4/12"> */}
					{/* <div>
                        <NotificationSection title="Top Selling Product" >
                            {
                                topSellingProducts.map((item, index) => {
                                    return (
                                        <div className="flex mt-6" key={index}>
                                            <div className="mr-2.5">
                                                <img src={item.image} className="h-[55px] w-[55px] rounded" />
                                            </div>
                                            <div className="flex justify-between flex-col">
                                                <div className="text-[15px]">{item.title}</div>
                                                <p className="text-[13px] text-[#828282]">{item.reviews}reviews</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </NotificationSection>
                    </div> */}

					{/* ----- Top Selling Product End (Warning: Do not Remove) ----- */}

					{/* ----- Recent Activity Start (Warning: Do not Remove) ----- */}

					{/* <div className="mt-6">
                        <NotificationSection title="Recent Activity">
                            <div className=" border-l border-[#BDBDBD]">
                                {recentActivity.map((item, index) => {
                                    return (
                                        <div className="mt-6 pl-2.5 recent-activity-wrapper relative" key={index}>
                                            <div className="flex items-center">
                                                <span className="text-[15px] mr-2.5">{item.status}</span>
                                                <span className="text-[13px] text-[#828282]">{item.time}</span>
                                            </div>
                                            <div className="pt-2 flex items-center text-sm">
                                                <span className="text-[#2D9CDB]">{item.name}</span>
                                                <span>:{item.title}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </NotificationSection>
                    </div> */}

					{/* ----- Recent Activity End (Warning: Do not Remove) ----- */}

					{/* </div> */}
				</div>
			</div>
		</>
	);
};

export default DashBoard;
