import React from "react";
const PrimaryPopupWrapper = ({ handleClosePopup, children, title }) => {
	return (
		<div
			className="fixed top-0 left-0 w-full h-full  change-password-container flex justify-center items-center z-10"
			id="change-password-container"
		>
			<div className="w-[500px] bg-white z-20 rounded">
				<div className="flex justify-between items-center p-6 border-b border-[#BDBDBD]">
					<h2 className="text-[15px] font-medium">{title}</h2>
					<img
						src="/images/icons/cross.svg"
						alt="close icon"
						className="h-[9px] w-[9px] cursor-pointer"
						id="close-popup-icon"
						onClick={handleClosePopup}
					/>
				</div>
				<div>{children}</div>
			</div>
		</div>
	);
};

export default PrimaryPopupWrapper;
