import React from "react";
import NavLinks from "../common/navbar/PrimaryNavbar";

const BillingLayout = ({ children }) => {
    const links = [{
        name: 'Billing',
        link: '/settings/billing'
    },
    {
        name: 'Billing History',
        link: '/settings/billing/history'
    }
    ]
    return (
        <div>
            <NavLinks links={links} />
            <div className="">
                {children}
            </div>
        </div>
    )
}
export default BillingLayout;