import React from "react";
import PrimaryButton from "../../common/buttons/PrimaryButton";

function InstallWidgetForm() {
    return (
        <form>
            <div className="bg-white">
                <div className="border-b border-[#BDBDBD] pb-32">
                    <div className="p-6">
                        <div className="text-base font-medium">
                            Installation
                        </div>
                        <div className="mt-3">
                            <p className="text-[13px]">
                                {`Main widget isn't showing on your site? Apply Reviewits main widget to your theme by clicking on the button below:`}
                            </p>
                            <div className="mt-3">
                                <PrimaryButton
                                    position="prepend"
                                    icon="/images/icons/downward-arrow.svg"
                                    styling="bg-white text-black border border-[#BDBDBD] rounded w-[149px] text-sm py-3 flex items-center justify-center"
                                    content="Install"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-6 flex justify-end items-center">
                    <PrimaryButton position="prepend" icon="/images/icons/save-btn.svg" styling="bg-[#60579E] text-white rounded w-[149px] text-sm py-3 flex items-center justify-center" content="Save" />
                </div>
            </div>
        </form>
    )
}

export default InstallWidgetForm