import React from "react";
import PrimaryButton from "../common/buttons/PrimaryButton";

const PlanCard = ({ item }) => {
    return (
        <div className="rounded border border-[#BDBDBD] mb-4 r-0 lg:mr-4 plan-cards min-h-[880px] 2xl:min-h-[840px]">
            <div className="border-b border-[#BDBDBD] md:h-[16%] lg:h-[18%] xl:h-[20%]">
                <div className="p-4">
                    <div className="text-[21px] font-medium">{item.title}</div>
                    <div className="text-sm mt-2">{item.status}</div>
                    <div className="mt-2">
                        <span className="text-lg font-medium">${item.price}</span>
                        <span className="text-sm text-[#828282] font-medium">/month</span>
                    </div>
                    <div className="mt-2 text-[#828282] font-[13px]">
                        billed annually
                    </div>
                </div>
            </div>
            <div className="p-4  flex flex-col justify-between h-[80%]">
                <ul>
                    <li className="flex items-center"><img src="/images/icons/plus.svg" height="15" width="15" className="mr-2" alt="" /><div className="font-medium text-sm">{item.type}</div></li>
                    {item.title == 'Professional' ?
                        <select name="" className="w-full mt-4 rounded border border-[#BDBDBD] bg-transparent px-[15px] py-[11px] text-[13px]" id="">
                            <option value="Upto 3500 orders monthly">Upto 3500 orders monthly</option>
                        </select> : null
                    }
                    {
                        item.features.map((item, index) => {
                            return (
                                <li className="flex items-center mt-4" key={index}>
                                    <div className="w-[10%]">
                                        <svg width="15" className="mr-2" height="10" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.6288 0.450622C19.1421 -0.0360323 18.3675 -0.25453 17.6623 0.450622L15.3284 2.78458L7.63126 10.4817L7.19427 10.9286L6.93604 10.6704L4.72127 8.4556L2.4171 6.15143C1.93045 5.66478 0.947209 5.66478 0.450622 6.15143C-0.0360323 6.63809 -0.25453 7.41277 0.450622 8.11792L2.78458 10.4519L4.78086 12.4482L6.20109 13.8684C6.83672 14.504 7.46242 14.5735 8.16758 13.8684L9.40904 12.6269L17.3246 4.71133L19.6189 2.4171C20.1254 1.93045 20.1254 0.947209 19.6288 0.450622Z" fill="#000" />
                                        </svg>
                                    </div>
                                    <div className="text-[13px]">{item}</div>
                                </li>
                            )
                        })
                    }

                </ul>
                <div className="">
                    <div className="w-full mb-2">
                        <PrimaryButton
                            content="Choose"
                            styling="w-full h-[39px] border border-[#BDBDBD] rounded bg-transparent flex justify-center text-sm items-center"
                        />
                    </div>
                    <div className="w-full">
                        <PrimaryButton
                            content="View all Features"
                            styling="w-full h-[39px] border border-[#BDBDBD] rounded bg-transparent flex justify-center text-sm items-center"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PlanCard;