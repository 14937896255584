import React from "react";
import PrimaryButton from "../common/buttons/PrimaryButton";

const AppConnectCard = ({ item }) => {
    const { icon, title, description, connect } = item;
    return (
        <div className="p-3 md:p-6 border border-[#BDBDBD] rounded flex justify-between">
            <div className="mr-3.5">
                <img src={icon} className="h-[44px] w-[44px]" alt="" />
            </div>
            <div className="w-[55%] mr-3.5">
                <div className="text-sm font-medium">
                    {title}
                </div>
                <p className="text-sm mt-[10px]">
                    {description}
                </p>
            </div>
            <div className="flex items-center w-[28%]">
                <PrimaryButton
                    content={connect ? 'Connect' : 'Disconnect'}
                    styling={connect ? 'w-full h-[39px] bg-[#60579E] text-white flex justify-center items-center rounded' : 'w-full h-[39px] bg-transparent text-black border border-[#BDBDBD] rounded flex justify-center items-center'}
                />
            </div>
        </div>
    )
}
export default AppConnectCard;